import React, { useContext } from 'react'
import { useStoreState } from 'easy-peasy'
import styled, { ThemeContext } from 'styled-components'
import { theme } from 'styled-tools'

import { Text, Icon } from '@somarmeteorologia/momentum'

import { Widget } from '@platform/components'

const Container = styled.div`
  padding: 25px 0px 10px 18px;
`

const Data = styled.div`
  display: flex;
  align-items: flex-end;
`
const Value = styled(Text)`
  line-height: 140%;
`

const Measure = styled(Text)`
  line-height: 150%;
`

export function ANEForecastWeekWidget() {
  const {
    text,
    colors: { green, red }
  } = useContext(ThemeContext)

  const isUpwardTrend = () => (Math.random() % 2 ? false : false)

  const layout = {
    title: 'ENA Previsão Semanal – Reservatório',
    content: () => (
      <Container>
        <Data>
          <Value size={Text.size.twentyEight} family={Text.family.roboto} right={12}>
            10.340
          </Value>
          <Measure
            size={Text.size.sixteen}
            family={Text.family.roboto}
            color={text.disabled}
            right={10}
          >
            MWmed
          </Measure>
          <Icon
            name={isUpwardTrend() ? 'up' : 'down'}
            color={isUpwardTrend() ? green.fifty : red.fifty}
          />
        </Data>
        <Data>
          <Value
            size={Text.size.twentyEight}
            family={Text.family.roboto}
            top={30}
            right={12}
          >
            109%
          </Value>
          <Measure
            size={Text.size.sixteen}
            family={Text.family.roboto}
            color={text.disabled}
            right={10}
          >
            MLT
          </Measure>
          <Icon
            name={isUpwardTrend() ? 'up' : 'down'}
            color={isUpwardTrend() ? green.fifty : red.fifty}
          />
        </Data>
        <Text top={60} size={Text.size.twelve}>
          Fonte: ONS
        </Text>
      </Container>
    )
  }

  return <Widget layout={layout} size={Widget.size.small} />
}
