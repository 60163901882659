import React, { useContext } from 'react'
import Plotly from 'plotly.js-basic-dist'
import { ThemeContext } from 'styled-components'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as csDictionary from 'plotly.js/lib/locales/pt-br.js'
import dayjs from 'dayjs'

import { Text } from '@somarmeteorologia/momentum'

import { Widget } from '@platform/components'

const TYPE = {
  load: 'load',
  generation: 'generation'
}

const TITLES = {
  load: 'Carga - ',
  generation: 'Geração'
}

export function EnergyLoadWidget({ data, size, title, type }) {
  const {
    bg,
    text,
    colors,
    font: {
      family: { inter }
    },
    font
  } = useContext(ThemeContext)
  Plotly.register(csDictionary)
  const Plot = createPlotlyComponent(Plotly)

  const layout = {
    title: `${TITLES[type]} ${title}`,
    content: () => (
      <Plot
        data={[
          {
            x: data.periods,
            y: type === TYPE.load ? data.load : data.generation,
            fill: 'tozeroy',
            type: 'scatter',
            hovertemplate: '%{x} <br> %{y} <extra></extra>',
            marker: {
              color: type === TYPE.load ? colors.yellow.fifty : colors.blue.fifty
            }
          }
        ]}
        layout={{
          separators: ',',
          width: size,
          height: 232,
          //dragmode: false,
          plot_bgcolor: bg.secondary,
          paper_bgcolor: bg.secondary,
          xaxis: {
            hoverformat: '%y/%m/%d às %H:%M',
            color: text.primary
          },
          yaxis: {
            color: text.primary
          },
          hovermode: 'closest',
          hoverlabel: {
            bgcolor: colors.white.hundred,
            bordercolor: '#E4E8ED',
            font: {
              family: inter,
              size: font.size.ten,
              color: colors.ebony.zero
            }
          },
          margin: {
            t: 15,
            b: 20,
            r: 25,
            l: 35
          }
        }}
        config={{ displayModeBar: false, locale: 'pt-br', dragmode: 'select' }}
      />
    ),
    footer: () => (
      <Text size={Text.size.thirteen} left={25} top={18} bottom={16} right={13}>
        Atualizado em:
        {` ${dayjs(dayjs(data.meta.updated_at).add(dayjs().utcOffset(), 'minute')).format(
          'DD [de] MMM [às] HH:mm'
        )}`}
      </Text>
    )
  }

  return (
    <Widget
      layout={layout}
      size={size === 650 ? Widget.size.large : Widget.size.medium}
    />
  )
}

EnergyLoadWidget.type = TYPE
