export const buildDataTable = (data, domain) => {
  const { periods, points } = data

  const filtred = points.find((point) => point.domain === domain)
  if (!filtred) {
    return {
      periods: [],
      precipitation: [],
      temperature: []
    }
  }
  return {
    periods: periods.slice(1, 8),
    precipitation: filtred.precipitation.slice(1, 8),
    temperature: filtred.temperature.slice(1, 8)
  }
}

export const buildDataChartMultiModel = (models, domain) => {
  const result = { temperature: [], precipitation: [] }

  Object.entries(models).forEach(([modelName, model]) => {
    if (model) {
      const { periods, points } = model
      const filtred = points ? points.find((point) => point.domain === domain) : null
      if (filtred) {
        result.temperature.push({
          x: periods.slice(0, 15),
          y: filtred.temperature.slice(0, 15),
          customdata: filtred.temperature
            .slice(0, 15)
            .map((value) => `${value.toString().replace('.', ',')}°C`),
          type: 'scatter',
          mode: 'lines',
          name: modelName,
          marker: {
            symbol: 'circle'
          },
          hovertemplate: '<b>%{customdata}</b>'
        })

        result.precipitation.push({
          x: periods.slice(0, 15),
          y: filtred.precipitation.slice(0, 15),
          customdata: filtred.precipitation
            .slice(0, 15)
            .map((value) => `${value.toString().replace('.', ',')}mm`),
          type: 'scatter',
          mode: 'lines',
          name: modelName,
          marker: {
            symbol: 'circle'
          },
          hovertemplate: '<b>%{customdata}</b>'
        })
      }
    }
  })

  return result
}
