import React, { Suspense } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import { Signin, Recovery, Code, Challenge } from './pages'

import AuthenticatedRoutes from './AuthenticatedRoutes'
import { PrivateRouter } from './containers'

function Router() {
  return (
    <Suspense fallback={<div>loading</div>}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Signin} />
          <Route path="/recovery" exact component={Recovery} />
          <Route path="/code" exact component={Code} />
          <Route path="/challenge" exact component={Challenge} />
          <PrivateRouter
            path={['/painel', '/water-forecast', '/weather', '/challenge', '/specialist']}
          >
            <AuthenticatedRoutes />
          </PrivateRouter>
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}

export default Router
