import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { equals, isNil, not } from 'ramda'
import { Input, Text, Icon, Notification } from '@somarmeteorologia/momentum'

import { signin } from '../services'

import { AuthenticableInterface } from '../containers'
import { getError, getTenant, STATE } from '../helpers'
import { Loading } from '../components'
import dayjs from 'dayjs'

const Options = styled.div`
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-end;
`

const Recovery = styled(Text)`
  cursor: pointer;
`

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Formato inválido').required('Obrigatório'),
  password: Yup.string().min(8, 'Mínimo de 8 caracteres').required('Obrigatório')
})

const initialValues = {
  email: '',
  password: ''
}

export const Signin = memo(({ history }) => {
  const setUser = useStoreActions(({ user }) => user.set)
  const { access_token } = useStoreState(({ user }) => user.data)
  const [state, setState] = useState(STATE.default)
  const [message, setMessage] = useState('')
  const [hasOrganization, setHasOrganization] = useState(false)

  // const [remember, setRemember] = useState(false)

  const { handleChange, values, isValid, errors, validateForm } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema
  })

  const { email, password } = values

  const onClick = () => {
    validateForm()

    isValid && onIsValid()
  }

  const whenHasNotOrganization = () => {
    setHasOrganization(false)
    setState(STATE.error)
    setMessage('Verifique a URL da sua organização!')
  }

  useEffect(() => {
    isNil(getTenant()) ? whenHasNotOrganization() : setHasOrganization(true)

    not(isNil(getTenant())) && access_token && history.push('/specialist')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onIsValid = () => {
    const organization = `${getTenant()}.platform.somar.io`

    setState(STATE.loading)

    signin({
      email,
      password,
      organization
    })
      .then(
        ({ session, challenge, access_token, expires_in, refresh_token, token_type }) => {
          setState(STATE.default)

          setUser({
            email,
            password,
            session,
            organization,
            challenge,
            access_token,
            refresh_token,
            expires_in: dayjs().add(expires_in - 120, 's')
          })

          isChallengeNewPassword(challenge) && history.push('/challenge')

          access_token && history.push('/specialist')
        }
      )
      .catch((error) => {
        setState(STATE.error)
        setMessage(error)
      })
  }

  const getWithErrors = getError(errors)

  const isChallengeNewPassword = (challenge) => challenge === 'NEW_PASSWORD_REQUIRED'

  return (
    <>
      {equals(state, STATE.loading) && <Loading />}

      <Notification
        icon={({ color }) => <Icon name="disabled" color={color} />}
        state={Notification.state.danger}
        isOpen={equals(state, STATE.error)}
        toClose={() => setState(STATE.default)}
        title={({ color }) => (
          <Text.Heading size={Text.Heading.size.sixteen} color={color}>
            Algo de errado aconteceu
          </Text.Heading>
        )}
        description={({ color }) => (
          <Text size={Text.size.thirteen} color={color}>
            {message}
          </Text>
        )}
      />

      <AuthenticableInterface
        onClick={onClick}
        disabled={not(hasOrganization)}
        type="signin"
      >
        <Input
          data-testid="email"
          full={true}
          raw={true}
          id="email"
          label="Usuário"
          placeholder="Utilize seu email corporativo"
          value={email}
          onChange={handleChange}
          error={getWithErrors('email')}
        />

        <Input
          data-testid="password"
          full={true}
          raw={true}
          type="password"
          id="password"
          label="Senha"
          placeholder="Digite sua senha"
          value={password}
          onChange={handleChange}
          error={getWithErrors('password')}
        />

        <Options>
          {/* <Checkbox
            id="remember"
            labelAlign="right"
            label="Lembrar meu usuário"
            checked={remember}
            onChange={setRemember}
          /> */}

          <Recovery
            onClick={() => history.push('/recovery')}
            size={Recovery.size.fourteen}
          >
            Recuperar senha
          </Recovery>
        </Options>
      </AuthenticableInterface>
    </>
  )
})
