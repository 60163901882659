import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import dayjs from 'dayjs'
import { theme } from 'styled-tools'
import { buildDataTable } from './utils'

import { Text } from '@somarmeteorologia/momentum'

const Container = styled.div`
  padding: 0px 51px;
  background-color: ${theme('datatable.bg.primary')};
`

const TableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;
`

const TableHead = styled.thead`
  background-color: ${theme('datatable.bg.primary')};
`

const Th = styled.th`
  width: 14%;
  border: 1px solid ${theme('bg.primary')};
  border-bottom: 0px;
  line-height: 150%;
  height: 120px;

  div {
    display: block;
  }
`

const TableRow = styled.tr``

const TableBody = styled.tbody`
  td:first-child {
    border-top: 0px;
  }
  td {
    background-color: ${theme('bg.tertiary')};
    height: 44px;
    border: ${theme('datatable.border.primary')};
    line-height: 160%;
    text-align: center;
  }
`

export const WeatherTable = ({ data, domain }) => {
  const { text } = useContext(ThemeContext)
  const { periods, precipitation, temperature } = buildDataTable(data, domain)

  return (
    <Container>
      <TableContainer>
        <TableHead>
          <TableRow>
            {periods.map((period) => {
              const date = new Date(`${period}T00:00:00`)
              return (
                <Th key={period}>
                  <Text.Heading
                    size={Text.Heading.size.sixteen}
                    color={text.primary}
                  >{`${dayjs(date).format('dddd')}`}</Text.Heading>
                  <Text
                    size={Text.size.twelve}
                    color={text.primary}
                    weight={Text.weight.light}
                  >{`${dayjs(date).format('DD [de] MMMM')}`}</Text>
                </Th>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {temperature.map((temperature, index) => (
              <td key={`${temperature}-${index}`}>
                <Text size={Text.size.twelve} family={Text.family.roboto}>
                  {`${temperature.toString().replace('.', ',')}°C`}
                </Text>
              </td>
            ))}
          </TableRow>
          <TableRow>
            {precipitation.map((precipitation, index) => (
              <td key={`${precipitation}-${index}`}>
                <Text
                  size={Text.size.twelve}
                  family={Text.family.roboto}
                >{`${precipitation.toString().replace('.', ',')}mm`}</Text>
              </td>
            ))}
          </TableRow>
        </TableBody>
      </TableContainer>
    </Container>
  )
}
