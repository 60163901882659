import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useInterval from '@use-it/interval'
import { not, add } from 'ramda'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Player as Play } from '@somarmeteorologia/momentum'

import { zeroLeft } from '../helpers'

const Container = styled.div`
  width: calc(100% - 100px);
  height: calc(100% - 70px);
  position: absolute;
  margin-left: 70px;
  margin-right: 40px;
`

export const Player = ({ dates }) => {
  const [played, setPlayed] = useState(false)
  const [index, setIndex] = useState(0)
  const { type } = useStoreState(({ weatherEnergy }) => ({
    type: weatherEnergy.map.type
  }))
  const { setDate } = useStoreActions(({ weatherEnergy }) => ({
    setDate: weatherEnergy.map.setDate
  }))

  useInterval(
    () => {
      const nextIndex = index === dates.length - 1 ? 0 : add(index, 1)
      setIndex(nextIndex)
      setDate(dates[nextIndex])
    },
    played ? 2000 : null
  )

  useEffect(() => {
    not(played) && dates[index] && setDate(dates[index])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, dates])

  const onPlayPause = () => setPlayed(not(played))

  const indexes = dates.map((date) => {
    const { date: day, months, years } = date.toObject()
    return type === 'periods'
      ? `${zeroLeft(day)}/${zeroLeft(months + 1)}/${years} UTC`
      : `${zeroLeft(day)}/${zeroLeft(months + 1)}/${years}`
  })

  return (
    <Container>
      <Play
        played={played}
        onPlayPause={onPlayPause}
        onPreviousNext={setIndex}
        index={index}
        indexes={indexes}
      />
    </Container>
  )
}
