import React, { memo } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Header } from '../components/'

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Content = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: ${theme('bg.primary')};
`

export const AuthenticatedPage = memo(({ children, title }) => {
  return (
    <Container>
      <Content>
        <Header title={title} />
        {children}
      </Content>
    </Container>
  )
})
