import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStoreState } from 'easy-peasy'
import { theme } from 'styled-tools'

const Content = styled.div`
  position: absolute;
  right: 20px;
  bottom: 80px;
  z-index: ${theme('zindex.above')};
`

const maper = {
  tmin: { day: 'temperature', week: 'temperature' },
  tmax: { day: 'temperature', week: 'temperature' },
  prec: {
    day: 'precipitation_daily',
    week: 'precipitation_acumulatade_weekly'
  },
  desvprec: {
    day: 'deviation_precipitation_daily',
    week: 'deviation_precipitation_weekly'
  },
  desvtmin: { day: 'deviation_temperature', week: 'deviation_temperature' },
  desvtmax: { day: 'deviation_temperature', week: 'deviation_temperature' }
}

export const MapCaption = () => {
  const [image, setImage] = useState(null)
  const { variable, type } = useStoreState(({ weatherEnergy }) => ({
    ...weatherEnergy.map
  }))

  const load = (variable, type) => {
    return import(`../assets/images/captions/${maper[variable][type]}.svg`).then((res) =>
      setImage(res.default)
    )
  }
  useEffect(() => {
    type && variable ? load(variable, type) : setImage(null)
  }, [type, variable])

  return <Content>{image && <img src={image} alt="" />}</Content>
}
