import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import dayjs from 'dayjs'

import { AuthenticatedPage, Mapeable } from '../containers'
import { Player } from '../components'
import { PAGE } from '../config'

export function Map() {
  const [dates, setDates] = useState([])
  const { type, model } = useStoreState(({ weatherEnergy }) => ({
    type: weatherEnergy.map.type,
    model: weatherEnergy.map.model
  }))
  const { setToOpen } = useStoreActions(({ config }) => ({
    ...config
  }))

  useEffect(() => {
    setToOpen(PAGE.weatherMap.id)
  }, [setToOpen])

  useEffect(() => {
    let arrDates = []
    let actualDay = dayjs(Date.now())
    const days = {
      day: 15,
      periods: 15,
      week: 7
    }

    for (let index = 0; index < days[type]; index++) {
      arrDates.push(actualDay.add(index, 'day'))
    }

    setDates(arrDates)
  }, [type, model])

  return (
    <AuthenticatedPage>
      <Player dates={dates} />
      <Mapeable></Mapeable>
    </AuthenticatedPage>
  )
}
