import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const Content = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: ${theme('zindex.above')};
  color: ${theme('text.primary')};
  font-family: ${theme('font.family.inter')};
  font-size: ${theme('font.size.sixteen')};
`

const formatDate = (date) => {
  return date.format('DD/MM/YYYY')
}
export const DateMap = ({ startDate, endDate }) => {
  if (endDate) {
    return <Content>{`De ${formatDate(startDate)} à ${formatDate(endDate)}`}</Content>
  } else {
    return <Content>{`${formatDate(startDate)}`}</Content>
  }
}
