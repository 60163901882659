import React, { memo } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'

import { Button, Text } from '@somarmeteorologia/momentum'

import somar from '../assets/images/somar.svg'

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme('bg.primary')};
  padding: 40px 0;
`

const Content = styled.div`
  width: 100%;
  max-width: 460px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  border-radius: ${theme('border.radius.four')};
  background-color: ${theme('bg.tertiary')};

  label {
    margin-bottom: 20px;
  }
`

const Logo = styled.img`
  margin: 60px 0;
`

const Action = styled(Button)`
  margin-top: 20px;
`

const Copyright = styled(Text)`
  text-align: center;
`

export const AuthenticableInterface = memo(({ disabled, onClick, type, children }) => {
  const getTextByType = {
    code: 'Confirmar código de recuperação',
    recovery: 'Enviar email de recuperação',
    challenge: 'Atualizar senha',
    signup: 'Cadastrar',
    nextSignup: 'Próxima etapa',
    signin: 'Entrar',
    new_password: 'Redefinir senha',
    feedback_password: 'Entrar'
  }

  return (
    <Container>
      <Content>
        <Logo src={somar} />

        {children}

        <Action
          data-testid="submit"
          disabled={disabled}
          onClick={onClick}
          appearence={Action.appearence.primary}
        >
          {getTextByType[type]}
        </Action>

        <Copyright size={Copyright.size.fourteen} top={40}>
          © 2020 SOMAR Meteorologia, todos os direitos reservados.
        </Copyright>
      </Content>
    </Container>
  )
})

AuthenticableInterface.defaultProps = {
  disabled: false,
  onClick: () => {}
}

AuthenticableInterface.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
