import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { theme } from 'styled-tools'

import { Text } from '@somarmeteorologia/momentum'

import { PAGE } from '../config/index'

const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  border: 1px solid ${theme('bg.secondary')};

  .back {
    margin-top: 3px;
    margin-right: 5px;
  }
`

const customTitles = ['/specialist/details']

export const Header = withRouter(({ match, title }) => {
  const { text } = useContext(ThemeContext)

  const getTitle = () => {
    if (customTitles.includes(match.path)) return title

    return Object.values(PAGE).find((page) => page.path === match.path).title
  }

  return (
    <Container>
      <Text.Heading left={10} size={Text.Heading.size.twentyOne} color={text.disabled}>
        {getTitle()}
      </Text.Heading>
    </Container>
  )
})
