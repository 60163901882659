import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Text } from '@somarmeteorologia/momentum'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const Feedback = ({ type, onClick }) => {
  const { text } = useContext(ThemeContext)

  const mapping = {
    error: {
      title: 'Algo de errado!',
      description: 'Não conseguimos identificar o ocorrido, volte para a tela de login.',
      button: 'Voltar para login'
    },
    redefined: {
      title: 'Senha redefinida com sucesso!',
      description:
        'Pronto, agora você está com uma nova senha e já pode acessar o portal com ela.'
    }
  }

  const { title, description } = mapping[type]

  return (
    <Container>
      <div>
        <Text size={Text.size.twentyEight} color={text.primary} weight="bold" bottom={10}>
          {title}
        </Text>

        <Text size={Text.size.fourteen} color={text.secondary}>
          {description}
        </Text>
      </div>
    </Container>
  )
}
