import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SideBar } from './components'
import { Painel, WaterForecast, Weather, Map, WordPress, Details } from './pages'

function AuthenticatedRoutes() {
  return (
    <SideBar>
      <Switch>
        <Route path="/painel" exact component={Painel} />
        <Route path="/specialist" exact component={WordPress} />
        <Route path="/specialist/details" exact component={Details} />
        <Route path="/water-forecast" exact component={WaterForecast} />
        <Route path="/weather" exact component={Weather} />
        <Route path="/weather/map" exact component={Map} />
      </Switch>
    </SideBar>
  )
}

export default AuthenticatedRoutes
