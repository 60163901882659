import React, { useEffect, useState, useContext } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import styled, { ThemeContext } from 'styled-components'
import { prop } from 'styled-tools'

import { Text, Button, Icon } from '@somarmeteorologia/momentum'

import { Content } from '@platform/components'
import { ChartWaterForecast, buildDataChart } from '@platform/energy'

import {
  fetchBasin,
  fetchStation,
  fetchStationInfos,
  fetchSubsystem,
  fetchEER
} from '../services'
import { AuthenticatedPage } from '../containers'
import { UpdateAt, Loading } from '../components'
import { GROUPING_LEVEL_ENA, PAGE, ENA_HISTORY_OPTIONS } from '../config'
import {
  STATE,
  getURLDownloadKrankeForecast,
  getURLDownloadKrankeObserved
} from '../helpers'

const TextWithLineHeight = styled(Text)`
  line-height: ${prop('lineHeight', 0)};
  width: 100%;
`

const Container = styled(Content)`
  overflow-y: auto;
  height: calc(100vh - 100px);
`

const PageItem = styled.div`
  margin-bottom: 40px;
`

const TitleItem = styled.div`
  display: flex;
  white-space: nowrap;
  a {
    margin-left: 5px;
  }
`

const getHitoryLimit = (histrorySelected) => {
  const historyTime = ENA_HISTORY_OPTIONS.find(
    (option) => option.value === histrorySelected
  ).time
  return new Date(Date.now() - historyTime)
}

export function WaterForecast() {
  const { text, colors } = useContext(ThemeContext)
  const {
    eer,
    subsystem,
    basin,
    stations,
    histrorySelected,
    frequencySelected,
    selected,
    stationsInfos,
    loading
  } = useStoreState(({ energy, waterForecast, config }) => ({
    ...waterForecast,
    ...config,
    ...energy
  }))
  const {
    setBasin,
    setEER,
    setSubsystem,
    setStations,
    setStationsInfos
  } = useStoreActions(({ waterForecast, energy }) => ({ ...waterForecast, ...energy }))
  const { setToOpen } = useStoreActions(({ config }) => config)
  const [dataChart, setDataChart] = useState({ ENA: [], pMLT: [] })
  const [updateAt, setUpdateAt] = useState()
  const [state, setState] = useState(STATE.loading)
  const [loadingStationInfos, setLoadingStationInfos] = useState(STATE.default)

  const { groupingLevelSelected, domain } = selected

  useEffect(() => {
    setToOpen(PAGE.waterForecast.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isMount = true

    setLoadingStationInfos(STATE.loading)
    if (groupingLevelSelected === GROUPING_LEVEL_ENA.station.id && !stationsInfos) {
      fetchStationInfos().then((infos) => {
        isMount && setStationsInfos(infos)
        isMount && setLoadingStationInfos(STATE.success)
      })
    } else {
      setLoadingStationInfos(STATE.success)
    }

    return () => {
      isMount = false
    }
  }, [groupingLevelSelected, setStationsInfos, stationsInfos])

  useEffect(() => {
    let isMount = true

    const mapper = {
      eer: eer,
      subsystem: subsystem,
      basin: basin,
      station: stations
    }

    if (!mapper[groupingLevelSelected]) {
      setState(STATE.loading)
      if (groupingLevelSelected === GROUPING_LEVEL_ENA.basin.id) {
        fetchBasin().then((response) => {
          isMount && setState(STATE.success)
          isMount && setBasin(response)
        })
      } else if (groupingLevelSelected === GROUPING_LEVEL_ENA.station.id) {
        fetchStation().then((response) => {
          isMount && setState(STATE.success)
          isMount && setStations(response)
        })
      } else if (groupingLevelSelected === GROUPING_LEVEL_ENA.subsystem.id) {
        fetchSubsystem().then((response) => {
          isMount && setState(STATE.success)
          isMount && setSubsystem(response)
        })
      } else if (groupingLevelSelected === GROUPING_LEVEL_ENA.eer.id) {
        fetchEER().then((response) => {
          isMount && setState(STATE.success)
          isMount && setEER(response)
        })
      }
    } else if (mapper[groupingLevelSelected]) {
      mapper[groupingLevelSelected][frequencySelected] &&
        setDataChart(
          buildDataChart(
            mapper[groupingLevelSelected][frequencySelected],
            domain,
            { colors },
            getHitoryLimit(histrorySelected)
          )
        )
      setUpdateAt(mapper[groupingLevelSelected][frequencySelected]?.meta?.updated_at)
      setState(STATE.success)
    }

    return () => (isMount = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    basin,
    colors,
    domain,
    eer,
    frequencySelected,
    groupingLevelSelected,
    histrorySelected,
    setBasin,
    setEER,
    setStations,
    setStationsInfos,
    setSubsystem,
    // state,
    stations,
    subsystem
  ])

  const getStationInfo = () => {
    if (stationsInfos) {
      const index = stationsInfos.stationId.indexOf(parseInt(domain))

      return `${stationsInfos.city[index]} ${stationsInfos.latitude[index]}, ${stationsInfos.longitude[index]}`
    }
    return ''
  }

  const getStationName = () => {
    if (stationsInfos) {
      const index = stationsInfos.stationId.indexOf(parseInt(domain))

      return stationsInfos.name[index]
    }
    return ''
  }

  return (
    <AuthenticatedPage>
      <UpdateAt date={updateAt} />
      <Container>
        {loading || state === STATE.loading || loadingStationInfos === STATE.loading ? (
          <Loading />
        ) : (
          <>
            <PageItem>
              <TitleItem>
                <TextWithLineHeight
                  size={Text.size.sixteen}
                  weight={Text.weight.light}
                  lineHeight={'160%'}
                  bottom={
                    groupingLevelSelected === GROUPING_LEVEL_ENA.station.id ? 2 : 14
                  }
                >
                  {`ENA Absoluta ${GROUPING_LEVEL_ENA[groupingLevelSelected].name} ${
                    groupingLevelSelected === GROUPING_LEVEL_ENA.station.id
                      ? `${getStationName()} (${domain})`
                      : domain
                  }`}
                </TextWithLineHeight>
                <Button
                  size={Button.size.small}
                  href={getURLDownloadKrankeObserved(
                    frequencySelected,
                    groupingLevelSelected,
                    domain
                  )}
                  target="_blank"
                  icon={(color) => (
                    <Icon
                      name={Icon.name.download}
                      width={22}
                      height={22}
                      color={color}
                    />
                  )}
                >
                  Dados Observados
                </Button>
                <Button
                  size={Button.size.small}
                  href={getURLDownloadKrankeForecast(
                    frequencySelected,
                    groupingLevelSelected,
                    domain
                  )}
                  target="_blank"
                  icon={(color) => (
                    <Icon
                      name={Icon.name.download}
                      width={22}
                      height={22}
                      color={color}
                    />
                  )}
                >
                  Dados Previsões
                </Button>
              </TitleItem>
              {groupingLevelSelected === GROUPING_LEVEL_ENA.station.id && (
                <TextWithLineHeight
                  size={Text.size.twelve}
                  weight={Text.weight.light}
                  lineHeight={'150%'}
                  color={text.quaternary}
                  bottom={14}
                >
                  {getStationInfo()}
                </TextWithLineHeight>
              )}
              <ChartWaterForecast
                data={dataChart.ENA}
                type={ChartWaterForecast.Types.ENA}
              />
            </PageItem>
            <PageItem>
              <TitleItem>
                <TextWithLineHeight
                  size={Text.size.sixteen}
                  weight={Text.weight.light}
                  lineHeight={'160%'}
                  bottom={
                    groupingLevelSelected === GROUPING_LEVEL_ENA.station.id ? 2 : 14
                  }
                >
                  {`ENA %MLT ${GROUPING_LEVEL_ENA[groupingLevelSelected].name} ${
                    groupingLevelSelected === GROUPING_LEVEL_ENA.station.id
                      ? `${getStationName()} (${domain})`
                      : domain
                  }`}
                </TextWithLineHeight>
              </TitleItem>
              {groupingLevelSelected === GROUPING_LEVEL_ENA.station.id && (
                <TextWithLineHeight
                  size={Text.size.twelve}
                  weight={Text.weight.light}
                  lineHeight={'150%'}
                  color={text.quaternary}
                  bottom={14}
                >
                  {getStationInfo()}
                </TextWithLineHeight>
              )}
              <ChartWaterForecast
                data={dataChart.pMLT}
                type={ChartWaterForecast.Types.pMLT}
              />
            </PageItem>
          </>
        )}
      </Container>
    </AuthenticatedPage>
  )
}
