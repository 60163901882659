import { action, createStore /*, thunk*/ } from 'easy-peasy'
import { composeWithDevTools } from 'redux-devtools-extension'
import dayjs from 'dayjs'

const state = {
  user: {
    data: {
      challenge: ''
    },
    set: action((state, data) => {
      state.data = data
    }),
    setEmail: action((state, email) => {
      state.data.email = email
    }),
    setOrganization: action((state, organization) => {
      state.data.organization = organization
    })
  },
  config: {
    toOpen: null,
    setToOpen: action((state, toOpen) => {
      state.toOpen = toOpen
    }),
    loading: true,
    setLoading: action((state, loading) => {
      state.loading = loading
    }),
    loadingWaterForecast: true,
    setLoadingWaterForecast: action((state, loading) => {
      state.loadingWaterForecast = loading
    }),
    loadingWeather: true,
    setLoadingWeather: action((state, loading) => {
      state.loadingWeather = loading
    })
  },
  energy: {
    selected: { groupingLevelSelected: 'subsystem', domain: 'S' },
    setSelected: action((state, selected) => {
      state.selected = selected
    }),
    stationsInfos: null,
    setStationsInfos: action((state, stationsInfos) => {
      state.stationsInfos = stationsInfos
    })
  },
  waterForecast: {
    eer: null,
    setEER: action((state, eer) => {
      state.eer = eer
    }),
    subsystem: null,
    setSubsystem: action((state, subsystem) => {
      state.subsystem = subsystem
    }),
    basin: null,
    setBasin: action((state, basin) => {
      state.basin = basin
    }),
    stations: null,
    setStations: action((state, stations) => {
      state.stations = stations
    }),
    histrorySelected: '3y',
    setHistrorySelected: action((state, histrorySelected) => {
      state.histrorySelected = histrorySelected
    }),
    frequencySelected: 'monthly',
    setFrequencySelected: action((state, frequencySelected) => {
      state.frequencySelected = frequencySelected
    })
  },
  weatherEnergy: {
    eer: null,
    setEER: action((state, eer) => {
      state.eer = eer
    }),
    subsystem: null,
    setSubsystem: action((state, subsystem) => {
      state.subsystem = subsystem
    }),
    basin: null,
    setBasin: action((state, basin) => {
      state.basin = basin
    }),
    stations: null,
    setStations: action((state, stations) => {
      state.stations = stations
    }),
    map: {
      model: 'gfs',
      setModel: action((state, model) => {
        state.model = model
      }),
      variable: 'prec',
      setVariable: action((state, variable) => {
        state.variable = variable
      }),
      type: 'day',
      setType: action((state, type) => {
        state.type = type
      }),
      date: dayjs(),
      setDate: action((state, date) => {
        state.date = date
      })
    },
    models: {
      wrf: null,
      setWrf: action((state, wrf) => {
        state.wrf = wrf
      }),
      gfs: null,
      setGfs: action((state, gfs) => {
        state.gfs = gfs
      }),
      cfs: null,
      setCfs: action((state, cfs) => {
        state.cfs = cfs
      }),
      ccm3: null,
      setCmm3: action((state, ccm3) => {
        state.ccm3 = ccm3
      }),
      gefs_emn: null,
      setGefs_emn: action((state, gefs_emn) => {
        state.gefs_emn = gefs_emn
      }),
      gefs_emx: null,
      setGefs_emx: action((state, gefs_emx) => {
        state.gefs_emx = gefs_emx
      }),
      gefs_avg: null,
      setGefs_avg: action((state, gefs_avg) => {
        state.gefs_avg = gefs_avg
      }),
      gefs_gp1: null,
      setGefs_gp1: action((state, gefs_gp1) => {
        state.gefs_gp1 = gefs_gp1
      }),
      gefs_gp2: null,
      setGefs_gp2: action((state, gefs_gp2) => {
        state.gefs_gp2 = gefs_gp2
      }),
      gefs_gp3: null,
      setGefs_gp3: action((state, gefs_gp3) => {
        state.gefs_gp3 = gefs_gp3
      }),
      gefs_gp4: null,
      setGefs_gp4: action((state, gefs_gp4) => {
        state.gefs_gp4 = gefs_gp4
      }),
      gefs_gp5: null,
      setGefs_gp5: action((state, gefs_gp5) => {
        state.gefs_gp5 = gefs_gp5
      }),
      gefs_gp6: null,
      setGefs_gp6: action((state, gefs_gp6) => {
        state.gefs_gp6 = gefs_gp6
      }),
      gefs_gp7: null,
      setGefs_gp7: action((state, gefs_gp7) => {
        state.gefs_gp7 = gefs_gp7
      }),
      gefs_gp8: null,
      setGefs_gp8: action((state, gefs_gp8) => {
        state.gefs_gp8 = gefs_gp8
      }),
      gefs_gp9: null,
      setGefs_gp9: action((state, gefs_gp9) => {
        state.gefs_gp9 = gefs_gp9
      }),
      gefs_gp10: null,
      setGefs_gp10: action((state, gefs_gp10) => {
        state.gefs_gp10 = gefs_gp10
      }),
      gefs_gp11: null,
      setGefs_gp11: action((state, gefs_gp11) => {
        state.gefs_gp11 = gefs_gp11
      }),
      gefs_gp12: null,
      setGefs_gp12: action((state, gefs_gp12) => {
        state.gefs_gp12 = gefs_gp12
      }),
      gefs_gp13: null,
      setGefs_gp13: action((state, gefs_gp13) => {
        state.gefs_gp13 = gefs_gp13
      }),
      gefs_gp14: null,
      setGefs_gp14: action((state, gefs_gp14) => {
        state.gefs_gp14 = gefs_gp14
      }),
      gefs_gp15: null,
      setGefs_gp15: action((state, gefs_gp15) => {
        state.gefs_gp15 = gefs_gp15
      }),
      gefs_gp16: null,
      setGefs_gp16: action((state, gefs_gp16) => {
        state.gefs_gp16 = gefs_gp16
      }),
      gefs_gp17: null,
      setGefs_gp17: action((state, gefs_gp17) => {
        state.gefs_gp17 = gefs_gp17
      }),
      gefs_gp18: null,
      setGefs_gp18: action((state, gefs_gp18) => {
        state.gefs_gp18 = gefs_gp18
      }),
      gefs_gp19: null,
      setGefs_gp19: action((state, gefs_gp19) => {
        state.gefs_gp19 = gefs_gp19
      }),
      gefs_gp20: null,
      setGefs_gp20: action((state, gefs_gp20) => {
        state.gefs_gp20 = gefs_gp20
      })
    }
  }
}

export const store = createStore(state, {
  compose: composeWithDevTools({ trace: true })
})
