import React, { useContext, useState } from 'react'
import styled, { ThemeContext, css } from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { Icon, Text } from '@somarmeteorologia/momentum'

import logo from '../../assets/images/logo_1.svg'

const MODULES = [
  {
    id: 'energy',
    icon: 'LightningCircle',
    display: 'Energia'
  } //,
  // {
  //   id: 'monitoring',
  //   icon: 'monitoring',
  //   display: 'Monitoramento'
  // },
  // {
  //   id: 'agro',
  //   icon: 'agriculture',
  //   display: 'Agro Somar'
  // }
]

const Container = styled.div`
  height: 100vh;
  width: 70px;
  background-color: ${theme('bg.primary')};
`

const Logo = styled.img`
  margin: 13px 14px 15px;
  width: 48px;
  height: 48px;
`

const Modules = styled.div`
  display: flex;
  flex-direction: column;
`

const Module = styled.div`
  height: 60px;
  width: 60px;
  margin: 20px 5px 5px;
  text-align: center;

  svg {
    margin: 8px 15px 0px;
  }

  span {
    line-height: 160%;
  }

  ${ifProp(
    'selected',
    css`
      background-color: ${theme('icons.primary')};
      border-radius: ${theme('border.radius.four')};
    `
  )};
`

export function GlobalMenu() {
  const [module, setModule] = useState('energy')
  const { icons, text, bg } = useContext(ThemeContext)

  return (
    <Container>
      <Logo src={logo} />
      <div>
        <Modules>
          {MODULES.map(({ id, icon, display }) => (
            <Module key={id} selected={id === module} onClick={() => setModule(id)}>
              <Icon
                name={icon}
                width={30}
                height={30}
                color={id === module ? icons.secondary : text.disabled}
              />
              {id === module && (
                <Text weight={Text.weight.bold} size={Text.size.ten} color={bg.primary}>
                  {display}
                </Text>
              )}
            </Module>
          ))}
        </Modules>
      </div>
    </Container>
  )
}
