import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { evolve, always } from 'ramda'
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as csDictionary from 'plotly.js/lib/locales/pt-br.js'

const config = {
  locale: 'pt-br'
}

const Types = {
  ENA: 'ENA',
  pMLT: 'pMLT'
}

export function ChartWaterForecast({ data, type }) {
  const {
    bg,
    text,
    font: {
      family: { inter }
    },
    colors,
    font
  } = useContext(ThemeContext)

  Plotly.register(csDictionary)
  const Plot = createPlotlyComponent(Plotly)

  const layout = {
    separators: ',',
    dragmode: 'zoom',
    plot_bgcolor: bg.secondary,
    paper_bgcolor: bg.secondary,
    xaxis: {
      title: 'Data',
      // fixedrange: true,
      tickformat: '%d %b %Y',
      color: text.primary
    },
    yaxis: {
      title: { text: 'Precipitação [mm]', font: { color: `${text.quaternary}99` } },
      fixedrange: true,
      color: text.primary
    },
    yaxis2: {
      title: { text: 'MLT [%]', font: { color: `${text.quaternary}99` } },
      overlaying: 'y',
      side: 'right',
      fixedrange: true,
      color: text.primary
    },
    legend: {
      y: -0.25,
      orientation: 'h',
      xanchor: 'center',
      x: 0.5,
      font: { color: text.primary, font: inter }
    },
    height: 500,
    margin: {
      t: 20,
      b: 10,
      r: 70,
      l: 70
    },
    hovermode: 'x unified',
    hoverlabel: {
      bgcolor: colors.white.hundred,
      bordercolor: '#E4E8ED',
      font: {
        family: inter,
        size: font.size.ten,
        color: colors.ebony.zero
      }
    }
  }

  return (
    <Plot
      config={config}
      data={data}
      layout={
        type === Types.ENA
          ? evolve(
              {
                yaxis2: { title: { text: always('ENA [MWmed]') } }
              },
              layout
            )
          : layout
      }
      style={{ width: '100%' }}
    />
  )
}

ChartWaterForecast.Types = Types
