import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { getUserData, getNewAccessToken } from '../services'
import { Loading } from '../components'
import dayjs from 'dayjs'

export function PrivateRouter({ children, location, ...rest }) {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)
  const { data } = useStoreState(({ user }) => user)
  const setUser = useStoreActions(({ user }) => user.set)

  useEffect(() => {
    const { access_token, refresh_token, expires_in } = data

    if (data.access_token) {
      if (expires_in.isBefore(dayjs())) {
        getNewAccessToken({ refresh_token, access_token })
          .then(({ access_token, expires_in }) => {
            setAuthenticated(true)
            setLoading(false)
            setUser({
              ...data,
              access_token,
              expires_in: dayjs().add(expires_in - 120, 's')
            })
          })
          .catch(() => {
            setAuthenticated(false)
            setLoading(false)
          })
      } else {
        getUserData({ access_token })
          .then((userData) => {
            setAuthenticated(userData.email)
            setLoading(false)
          })
          .catch(() => {
            setAuthenticated(false)
            setLoading(false)
          })
      }
    }
  }, [data, location.pathname, setUser])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        loading && data.access_token ? (
          <Loading />
        ) : isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )
      }
    ></Route>
  )
}
