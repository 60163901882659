import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const Container = styled.div`
  padding: 40px;
  color: ${theme('text.primary')};
`

export function Content({ className, children }) {
  return <Container className={className}>{children}</Container>
}
