import { Env } from './environment'
import { equals, not } from 'ramda'
import dayjs from 'dayjs'

export const BASE_URI_DOWNLOAD_KRAKEN = 'https://kraken-download.s3.amazonaws.com'

export const toJSON = (raw) => raw?.json()

export const getError = (errors) => (id) => ({
  has: !!errors[id],
  message: errors[id]
})

export const getTenant = () => {
  const getRegex = () =>
    Env.isProduction()
      ? /^([a-z0-9]+)\.*platform+\.somar+\.io/
      : /^([a-z0-9]+)\.*localhost/

  const address = getRegex().exec(window.location.hostname)

  return address ? address[1] : null
}

export const STATE = {
  default: 'DEFAULT',
  loading: 'LOADING',
  success: 'SUCCESS',
  error: 'ERROR'
}

export const dispatcher = (operations) => {
  operations.forEach((operation) => {
    const [newest, oldest, callable] = operation

    not(equals(newest, oldest)) && callable(newest)
  })
}

export const zeroLeft = (value) => {
  if (value < 10) {
    return '0' + value
  }
  return value
}

export const getURLDownloadKrankeForecast = (frequency, groupLevel, domain) => {
  const today = dayjs()

  const mapperFrequency = {
    monthly: 'mes',
    weekly: 'sem'
  }

  const mapperGroupLevel = {
    subsystem: 'sub',
    eer: 'ree',
    basin: 'bas',
    station: 'station'
  }

  return `${BASE_URI_DOWNLOAD_KRAKEN}/forecast/${frequency}/${
    mapperGroupLevel[groupLevel]
  }/${domain}/all/${today.year()}/${zeroLeft(today.month() + 1)}/${zeroLeft(
    today.date()
  )}/models_${domain}_${mapperFrequency[frequency]}_${today.year()}-${zeroLeft(
    today.month() + 1
  )}-${zeroLeft(today.date())}.zip`
}

export const getURLDownloadKrankeObserved = (frequency, groupLevel, domain) => {
  const today = dayjs()

  const mapperFrequency = {
    monthly: 'mes',
    weekly: 'sem'
  }

  const mapperGroupLevel = {
    subsystem: 'sub',
    eer: 'ree',
    basin: 'bas',
    station: 'station'
  }

  return `${BASE_URI_DOWNLOAD_KRAKEN}/observed/${frequency}/${
    mapperGroupLevel[groupLevel]
  }/${domain}/${today.year()}/${zeroLeft(today.month() + 1)}/${zeroLeft(
    today.date()
  )}/obs_${domain}_${mapperFrequency[frequency]}_${today.year()}-${zeroLeft(
    today.month() + 1
  )}-${zeroLeft(today.date())}.csv`
}
