import React, { memo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Text } from '@somarmeteorologia/momentum'

const Container = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${theme('bg.secondary')};
`

export const UpdateAt = memo(({ date }) => {
  return (
    <Container>
      <Text left={40} top={7} bottom={7} size={Text.size.ten}>
        Atualizado em:
        {date && (
          <Text left={2} weight={Text.weight.bold} size={Text.size.ten}>
            {`${dayjs(date).format('DD/MM/YYYY [às] HH:mm:ss A')}`}
          </Text>
        )}
      </Text>
    </Container>
  )
})
