import React, { memo, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { theme } from 'styled-tools'

import { Icon, Text } from '@somarmeteorologia/momentum'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${theme('zindex.above')};
  background-color: ${theme('bg.primary')};
  opacity: 0.8;
`

export const Loading = memo(({ message }) => {
  const { icons, text } = useContext(ThemeContext)

  return (
    <Container>
      <Icon name="loading" width={60} height={60} color={icons.primary} />
      <Text.Heading top={30} size={Text.Heading.size.twentyEight} color={text.primary}>
        Carregando...
      </Text.Heading>
      {message && (
        <Text.Heading top={30} size={Text.Heading.size.sixteen} color={text.primary}>
          {message}
        </Text.Heading>
      )}
    </Container>
  )
})
