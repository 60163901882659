import React, { memo } from 'react'
import { useStoreState } from 'easy-peasy'
import { always } from 'ramda'

import { Navigation as Navigable } from '@somarmeteorologia/momentum'

import Structure from './Structure'
import Interable from './Interable'
import External from './External'

const { Provider } = Navigable

export const Navigation = memo(() => {
  const { toOpen } = useStoreState(({ config }) => config)

  return (
    <>
      {toOpen && (
        <Interable>
          {({ interable, onInterable }) => (
            <Structure>
              {({ structure }) => (
                <Provider
                  defaultStructure={structure}
                  defaultInterables={interable}
                  onInterable={onInterable}
                >
                  <External />
                  <Navigable
                    key={toOpen}
                    toOpen={always(toOpen)}
                    height="calc(100vh - 170px)"
                  />
                </Provider>
              )}
            </Structure>
          )}
        </Interable>
      )}
    </>
  )
})
