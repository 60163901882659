import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useStoreActions } from 'easy-peasy'
import dayjs from 'dayjs'

import { Button } from '@somarmeteorologia/momentum'

import { AuthenticatedPage } from '../../containers'

import { PAGE } from '../../config'
import { STATE } from '../../helpers'

const Container = styled.div`
  overflow-y: auto;
  height: calc(100% - 70px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    min-height: 40px;
  }
`

const Posts = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* grid-gap: 10px; */
  color: ${theme('text.primary')};
  font-family: ${theme('font.family.inter')};

  .destaque {
    grid-column: span 2;
    grid-row: span 2;

    img {
      height: 300px;
    }

    span {
      font-size: 22px;
    }
  }
`

const Post = styled.div`
  flex-direction: row;
  img {
    height: 120px;
    margin-bottom: 15px;
  }
  span {
    font-size: 16px;
  }
`

const PostContent = styled.div`
  text-align: center;
  border-radius: 13px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PostDate = styled.div`
  color: #aaa;
  text-align: center;
  font-size: 12px;
`

export const WordPress = ({ history }) => {
  const [data, setData] = useState([])
  const [actualPage, setActualPage] = useState(1)
  const [loading, setLoading] = useState(STATE.loading)
  const { setToOpen } = useStoreActions(({ config }) => config)

  useEffect(() => {
    setToOpen(PAGE.specialist.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isMount = true
    setLoading(STATE.loading)
    fetch(
      `https://wp-dev.somar.io/wp-json/wp/v2/posts?per_page=5&page=${actualPage}`
    ).then((post) => {
      post.json().then((response) => {
        isMount && setData(response)
        isMount && setLoading(STATE.success)
      })
    })
    return () => {
      isMount = false
    }
  }, [actualPage])

  return (
    <AuthenticatedPage>
      <Container>
        <Posts>
          {data &&
            loading === STATE.success &&
            data.map(({ id, date, acf, title, content }, index) => (
              <Post
                key={id}
                className={index === 0 ? 'destaque' : ''}
                onClick={() =>
                  history.push({
                    pathname: '/specialist/details',
                    state: { post: content.rendered, title: title.rendered }
                  })
                }
              >
                <PostDate> {`${dayjs(date).format('DD/MM/YYYY')}`}</PostDate>
                <PostContent>
                  <img src={acf.data} alt={title.rendered} />
                  <span>{title.rendered}</span>
                </PostContent>
              </Post>
            ))}
        </Posts>
        {data.length > 0 && (
          <Button onClick={() => setActualPage(actualPage + 1)}>
            {loading === STATE.loading ? 'Carregando...' : 'Carregar mais'}
          </Button>
        )}
      </Container>
    </AuthenticatedPage>
  )
}
