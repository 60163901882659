const filterByDomain = (data, domain, historyLimit) => {
  const { observed, forecast } = data

  const filtredObserved = observed.values.find((value) => value.domain === domain)

  const filtredForecast = forecast.values.find((value) => value.domain === domain)

  const filtredPeriodsObserved = observed.periods.filter(
    (periods) => new Date(periods) >= historyLimit
  )

  const { ENA, precipitation, pMLT } = filtredObserved

  return {
    observed: {
      periods: filtredPeriodsObserved,
      precipitation: precipitation.slice(-filtredPeriodsObserved.length),
      ENA: ENA.slice(-filtredPeriodsObserved.length),
      pMLT: pMLT.slice(-filtredPeriodsObserved.length),
      domain: filtredObserved.domain
    },
    forecast: { periods: forecast.periods, ...filtredForecast }
  }
}

export const buildDataChart = (data, domain, style, historyLimit) => {
  const { observed, forecast } = filterByDomain(data, domain, historyLimit)
  const { colors } = style

  const colorModels = {
    HoltWinters: colors.blue.fifty,
    Prophet: colors.green.fifty,
    LightGBM: colors.yellow.fifty,
    KernelRidge: colors.orange.fifty,
    XGBoost: colors.ciano.fifty,
    SARIMAX: colors.pink.fifty,
    DeepFeedFwd: colors.gray.fifty,
    DeepConvNtwk: colors.yellow.twenty,
    ONSforecast: colors.orange.twenty,
    SOMARforecast: colors.yellow.ninety
  }

  const ENA = [
    {
      x: observed.periods,
      y: observed.precipitation,
      type: 'bar',
      name: 'Precipitação observada',
      marker: {
        color: colors.ebony.fifty
      }
    },
    (() => {
      if (!forecast.precipitation) return {}

      return {
        x: forecast.periods,
        y: forecast.precipitation,
        type: 'bar',
        name: 'Precipitação prevista',
        marker: {
          color: colors.cornflower.fifty,
          line: {
            color: 'rgba(255,255,255, 0.5)',
            width: 1.5
          }
        }
      }
    })(),
    {
      x: observed.periods,
      y: observed.ENA,
      type: 'scatter',
      mode: 'lines',
      yaxis: 'y2',
      name: 'ENA histórica',
      marker: {
        color: colors.gray.ninety
      }
    },

    ...(() => {
      let result = []
      if (forecast.models) {
        const models = forecast.models
        for (let i = 0; i < models.length; i++) {
          const model = models[i]
          result.push({
            x: forecast.periods,
            y: model.ENA,
            type: 'scatter',
            mode: 'lines',
            yaxis: 'y2',
            name: model.model,
            marker: {
              color: colorModels[model.model]
            }
          })
        }
      }
      return result
    })()
  ]

  const pMLT = [
    {
      x: observed.periods,
      y: observed.precipitation,
      type: 'bar',
      name: 'Precipitação observada',
      marker: {
        color: colors.ebony.fifty
      }
    },
    (() => {
      if (!forecast.precipitation) return {}

      return {
        x: forecast.periods,
        y: forecast.precipitation,
        type: 'bar',
        name: 'Precipitação prevista',
        marker: {
          color: colors.cornflower.fifty,
          line: {
            color: 'rgba(255,255,255, 0.5)',
            width: 1.5
          }
        }
      }
    })(),
    {
      x: observed.periods,
      y: observed.pMLT,
      type: 'scatter',
      mode: 'lines',
      yaxis: 'y2',
      name: '%MLT',
      marker: {
        color: colors.gray.ninety
      }
    },

    ...(() => {
      let result = []
      if (forecast.models) {
        const models = forecast.models
        for (let i = 0; i < models.length; i++) {
          const model = models[i]
          result.push({
            x: forecast.periods,
            y: model.pMLT,
            type: 'scatter',
            mode: 'lines',
            yaxis: 'y2',
            name: model.model,
            marker: {
              color: colorModels[model.model]
            }
          })
        }
      }
      return result
    })()
  ]

  return { ENA, pMLT }
}
