import React, { memo, useEffect } from 'react'
import { Input, Text } from '@somarmeteorologia/momentum'
import { useFormik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { getError } from '../../../helpers'
import { STEPS } from '..'

const Domain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const validationSchema = Yup.object().shape({
  organization: Yup.string().required('Obrigatório'),
  accountName: Yup.string().required('Obrigatório'),
  legalName: Yup.string().required('Obrigatório'),
  stateId: Yup.string().required('Obrigatório'),
  document: Yup.string().required('Obrigatório')
})

const initialValues = {
  organization: '',
  accountName: '',
  legalName: '',
  stateId: '',
  document: ''
}

export const Organization = memo(({ onChange }) => {
  const { handleChange, values, errors, validateForm } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema
  })

  const { organization, accountName, legalName, stateId, document } = values

  const getWithErrors = getError(errors)

  useEffect(() => {
    onChange({ type: STEPS.organization, values, validateForm })
  }, [values, validateForm, onChange])

  return (
    <>
      <Input
        data-testid="accountName"
        full={true}
        raw={true}
        id="accountName"
        label="Nome da empresa"
        placeholder="Digite o nome da empresa"
        value={accountName}
        onChange={handleChange}
        error={getWithErrors('accountName')}
      />

      <Input
        data-testid="stateId"
        full={true}
        raw={true}
        id="stateId"
        label="Inscrição estadual"
        placeholder="Digite a inscrição estadual"
        value={stateId}
        onChange={handleChange}
        error={getWithErrors('stateId')}
      />

      <Input
        data-testid="legalName"
        full={true}
        raw={true}
        id="legalName"
        label="Razão social"
        placeholder="Digite a razão social da empresa"
        value={legalName}
        onChange={handleChange}
        error={getWithErrors('legalName')}
      />

      <Input
        data-testid="document"
        full={true}
        raw={true}
        id="document"
        label="CNPJ"
        placeholder="Digite o CNPJ"
        value={document}
        onChange={handleChange}
        error={getWithErrors('document')}
      />

      <Domain>
        <Input
          data-testid="organization"
          full={true}
          raw={true}
          id="organization"
          label="Organização"
          placeholder="Digite um subdomínio"
          value={organization}
          onChange={handleChange}
          error={getWithErrors('organization')}
        />

        <Text>.platform.somar.io</Text>
      </Domain>
    </>
  )
})
