import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Helmet } from 'react-helmet'

import { Menu } from '@somarmeteorologia/momentum'

import { SideMenu } from './SideMenu'
import { GlobalMenu } from './GlobalMenu'

const ContainerMenu = styled.div`
  display: flex;
`

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Content = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: ${theme('bg.primary')};
`

export const SideBar = memo(({ children }) => {
  const [menuOpen, setMenuOpen] = useState(true)
  return (
    <Container>
      <Helmet>
        <style type="text/css">
          {`.calendly-badge-widget {
        right: auto !important;
        left: 75px !important;
        z-index: 10000;
        max-width: 260px;
        display: ${menuOpen ? 'table-cell' : 'none'};
      }`}
        </style>
        <script type="text/javascript">
          {`Calendly.initBadgeWidget({
            url: 'https://calendly.com/somarmeteorologia/15min',
            text: 'Agendar conversa com especialista',
            color: '#ffffff',
            textColor: '#080b19',
            branding: true
          })`}
        </script>
      </Helmet>
      <Menu isOpen={menuOpen} setOpen={setMenuOpen} width={340}>
        <ContainerMenu>
          <GlobalMenu />
          <SideMenu />
        </ContainerMenu>
      </Menu>
      <Content>{children}</Content>
    </Container>
  )
})
