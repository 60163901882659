export const GROUPING_LEVEL_ENA = {
  subsystem: {
    id: 'subsystem',
    name: 'Subsistema',
    icon: 'map'
  },
  eer: {
    id: 'eer',
    name: 'REE',
    icon: 'city'
  },
  basin: {
    id: 'basin',
    name: 'Bacia',
    icon: 'output'
  },
  station: {
    id: 'station',
    name: 'Posto',
    icon: 'energy'
  }
}

export const ENA_FREQUENCY = [
  { id: 'monthly', label: 'Mensal' },
  { id: 'weekly', label: 'Semanal' }
]

export const PAGE = {
  specialist: { id: 'energy', title: 'Palavra do Especialista', path: '/specialist' },
  painel: { id: 'painel', title: 'Painel', path: '/painel' },
  waterForecast: {
    id: 'waterForecast',
    title: 'Previsão Hídrica',
    path: '/water-forecast'
  },
  weather: {
    id: 'weather',
    title: 'Previsão do Tempo',
    path: '/weather'
  },
  weatherMap: {
    id: 'weatherMap',
    title: 'Mapa Previsão do Tempo',
    path: '/weather/map'
  }
}

const MILLISECONDS_ONE_DAY = 86400000
const MILLISECONDS_ONE_MONTH = MILLISECONDS_ONE_DAY * 30
const MILLISECONDS_ONE_YEAR = MILLISECONDS_ONE_DAY * 365

export const ENA_HISTORY_OPTIONS = [
  { value: '7d', text: '7 dias', time: MILLISECONDS_ONE_DAY * 7 },
  { value: '28d', text: '28 dias', time: MILLISECONDS_ONE_DAY * 28 },
  { value: '6m', text: '6 meses', time: MILLISECONDS_ONE_MONTH * 6 },
  { value: '1y', text: '1 ano', time: MILLISECONDS_ONE_YEAR },
  { value: '3y', text: '3 anos', time: MILLISECONDS_ONE_YEAR * 3 },
  { value: '5y', text: '5 anos', time: MILLISECONDS_ONE_YEAR * 5 }
]

export const MULTIMODEL = {
  wrf: 'wrf',
  gfs: 'gfs',
  cfs: 'cfs',
  ccm3: 'ccm3',
  gefs_emn: 'gefs_emn',
  gefs_emx: 'gefs_emx',
  gefs_avg: 'gefs_avg',
  gefs_gp1: 'gefs_gp1',
  gefs_gp2: 'gefs_gp2',
  gefs_gp3: 'gefs_gp3',
  gefs_gp4: 'gefs_gp4',
  gefs_gp5: 'gefs_gp5',
  gefs_gp6: 'gefs_gp6',
  gefs_gp7: 'gefs_gp7',
  gefs_gp8: 'gefs_gp8',
  gefs_gp9: 'gefs_gp9',
  gefs_gp10: 'gefs_gp10',
  gefs_gp11: 'gefs_gp11',
  gefs_gp12: 'gefs_gp12',
  gefs_gp13: 'gefs_gp13',
  gefs_gp14: 'gefs_gp14',
  gefs_gp15: 'gefs_gp15',
  gefs_gp16: 'gefs_gp16',
  gefs_gp17: 'gefs_gp17',
  gefs_gp18: 'gefs_gp18',
  gefs_gp19: 'gefs_gp19',
  gefs_gp20: 'gefs_gp20'
}
