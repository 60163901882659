import React, { useRef, useEffect } from 'react'
import { Map, TileLayer } from 'react-leaflet'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useStoreState } from 'easy-peasy'

import { MapCaption, DateMap } from '../components/'
import { zeroLeft } from '../helpers'

const Container = styled(Map)`
  width: 100%;
  height: calc(100% - 70px);
  position: relative;

  .leaflet-left .leaflet-bar {
    box-shadow: none;
  }

  .leaflet-bar a {
    transition: all 0.2s linear;

    &:hover {
      background-color: ${theme('colors.blue.fifty')};
    }
  }

  .icon {
    border: none;
    background: transparent;
  }

  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;
  }

  .leaflet-popup-content {
    margin: 0;
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-left .leaflet-control {
    margin-left: 17px;
    flex-direction: column;
  }

  .leaflet-top .leaflet-control {
    margin-top: 17px;
  }

  .leaflet-control-zoom {
    border: none;
    display: flex;

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-zoom-fullscreen {
      border-radius: ${theme('border.radius.fifty')} !important;
      border: none;
      width: 35px;
      height: 35px;
      line-height: 32px;
      color: ${theme('colors.ebony.zero')};

      &:hover {
        color: ${theme('colors.white.hundred')};
      }
    }

    .leaflet-control-zoom-out,
    .leaflet-control-zoom-fullscreen {
      margin-top: 8px;
    }

    a:last-child {
      background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.60976 9.46341H0.658537V14.3415H5.53659V12.3902H2.60976V9.46341ZM0.658508 5.56091H2.60973V2.63408H5.53656V0.682861H0.658508V5.56091ZM12.3659 12.3902H9.43902V14.3415H14.3171V9.46341H12.3659V12.3902ZM9.439020.682927V2.63415H12.3659V5.56098H14.3171V0.682927H9.43902Z" fill="black"/></svg>');
      background-size: 14px 14px;

      &:hover {
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14 " viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.60976 9.46341H0.658537V14.3415H5.53659V12.3902H2.60976V9.46341ZM0.658508 5.56091H2.60973V2.63408H5.53656V0.682861H0.658508V5.56091ZM12.3659 12.3902H9.43902V14.3415H14.3171V9.46341H12.3659V12.3902ZM9.439020.682927V2.63415H12.3659V5.56098H14.3171V0.682927H9.43902Z" fill="white"/></svg>');
      }
    }
  }
`

const center = {
  lat: -14.126402341186115,
  lng: -54.10072338201535
}

export function Mapeable({ children, onZoom, onMoveEnd, onResizeEnd }) {
  const mapRef = useRef()
  const { model, variable, type, date } = useStoreState(({ weatherEnergy }) => ({
    ...weatherEnergy.map
  }))

  const { date: day, months, years } = date.toObject()
  const endDate = variable === 'prec' && type === 'week' ? date.add(7, 'day') : null

  useEffect(() => {
    setTimeout(
      () => mapRef && mapRef.current && mapRef.current.leafletElement.invalidateSize(),
      1100
    )
  }, [])

  return (
    <Container
      ref={mapRef}
      center={center}
      zoom={6}
      minZoom={3}
      maxZoom={7}
      maxBoundsViscosity={1.0}
      attributionControl
      zoomControl={false}
      scrollWheelZoom
      easeLinearity={0.35}
      trackResize
      onZoom={onZoom}
      onMoveEnd={onMoveEnd}
      onResizeEnd={onResizeEnd}
    >
      <MapCaption />
      <DateMap startDate={date} endDate={endDate} />
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png"
        attribution={
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
        }
      />
      <TileLayer
        url={`https://tiles-somar.somar.io/${model}/${variable}/${type}/${years}/${zeroLeft(
          months + 1
        )}/${zeroLeft(day)}/06/{z}/{x}/{-y}.png`}
        opacity={0.5}
      />
      {children}
    </Container>
  )
}
