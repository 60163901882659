import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { equals, pipe, map, find, flatten, prop } from 'ramda'

import { dispatcher } from '../../helpers'

export default function Interable({ children }) {
  const {
    setHistrorySelected,
    setSelected,
    setFrequencySelected,
    setModelMap,
    setVariableMap,
    setTypeMap
  } = useStoreActions(({ energy, waterForecast, weatherEnergy }) => ({
    setHistrorySelected: waterForecast.setHistrorySelected,
    setFrequencySelected: waterForecast.setFrequencySelected,
    setSelected: energy.setSelected,
    setModelMap: weatherEnergy.map.setModel,
    setVariableMap: weatherEnergy.map.setVariable,
    setTypeMap: weatherEnergy.map.setType
  }))

  const {
    histrorySelected,
    selected,
    frequencySelected,
    modelMap,
    variableMap,
    typeMap
  } = useStoreState(({ energy, waterForecast, weatherEnergy }) => ({
    histrorySelected: waterForecast.histrorySelected,
    frequencySelected: waterForecast.frequencySelected,
    selected: energy.selected,
    modelMap: weatherEnergy.map.model,
    variableMap: weatherEnergy.map.variable,
    typeMap: weatherEnergy.map.type
  }))

  const interable = [
    { id: 'period', interables: [{ id: 'history', value: histrorySelected }] },
    {
      id: 'frequency',
      interables: [{ id: 'frequencySelected', value: frequencySelected }]
    },
    {
      id: 'location',
      interables: [
        { id: 'options', value: [] },
        { id: 'selected', value: selected }
      ]
    },
    { id: 'optionModel', interables: [{ id: 'model', value: modelMap }] },
    { id: 'variable', interables: [{ id: 'variableMap', value: variableMap }] },
    { id: 'frequencyMap', interables: [{ id: 'typeMap', value: typeMap }] }
  ]

  const onInterable = ({ interables }) => {
    const get = (array) => (id) => {
      return pipe(
        map(({ interables }) => interables),
        flatten,
        find((index) => equals(index.id, id)),
        prop('value')
      )(array)
    }

    const getById = get(interables)
    dispatcher([
      [getById('history'), histrorySelected, setHistrorySelected],
      [getById('selected'), selected, setSelected],
      [getById('frequencySelected'), frequencySelected, setFrequencySelected],
      [getById('model'), modelMap, setModelMap],
      [getById('variableMap'), variableMap, setVariableMap],
      [getById('typeMap'), typeMap, setTypeMap]
    ])
  }

  return <>{children({ interable, onInterable })} </>
}
