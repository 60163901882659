import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStoreActions } from 'easy-peasy'

import { Content as Container } from '@platform/components'
import { EnergyLoadWidget, InstantLoad } from '@platform/energy'

import { fetchEnergyLoad, fetchEnergyGeneration } from '../services'
import { AuthenticatedPage } from '../containers'
import { Loading } from '../components'
import { PAGE } from '../config'
import { STATE } from '../helpers'

const SUBS = ['SIN', 'N', 'NE', 'SE', 'S']

const Content = styled(Container)`
  overflow-y: auto;
  height: calc(100% - 70px);
  padding: 10px 15px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 650px 300px;
  grid-gap: 20px 15px;
  justify-content: center;
  margin-bottom: 20px;
`

const Subsystems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 10px;
`

const SubsystemItem = styled.div`
  display: grid;
  grid-template-columns: 446px 270px;
  grid-gap: 5px 10px;
  justify-content: center;
`

export function Painel() {
  const { setToOpen } = useStoreActions(({ config }) => config)
  const [state, setState] = useState(STATE.loading)
  const [dataLoad, setDataLoad] = useState({})

  useEffect(() => {
    let isMount = true
    setToOpen(PAGE.painel.id)
    setState(STATE.loading)
    const fetchs = SUBS.map((sub) => {
      return Promise.all([
        fetchEnergyLoad(sub).then((response) => {
          return { load: response }
        }),
        fetchEnergyGeneration(sub, 'hydro').then((response) => {
          return { generation: response }
        })
      ]).then((types) => {
        const reduceTypes = types.reduce((types, type) => {
          return { ...types, ...type }
        }, {})
        return { [sub]: reduceTypes }
      })
    })

    Promise.all(fetchs).then((subs) => {
      const reduceSubs = subs.reduce((subs, sub) => {
        return { ...subs, ...sub }
      }, {})
      setDataLoad(reduceSubs)
      isMount && setState(STATE.success)
    })
    return () => {
      isMount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { SIN } = dataLoad

  return (
    <AuthenticatedPage>
      {state === STATE.loading ? (
        <Loading />
      ) : (
        <Content>
          <Grid>
            <EnergyLoadWidget
              data={SIN.load}
              size={650}
              title={SIN.load.meta.subsystem}
              type={EnergyLoadWidget.type.load}
            />
            <InstantLoad
              value={SIN.load.load[SIN.load.load.length - 1]}
              upwardTrend={
                SIN.load.load[SIN.load.load.length - 1] >
                SIN.load.load[SIN.load.load.length - 2]
              }
              unit={SIN.load.meta.units.load}
              size={'small'}
              type={'Carga'}
              sub={SIN.load.meta.subsystem}
              updated_at={SIN.load.meta.updated_at}
            />
            <EnergyLoadWidget
              data={SIN.generation}
              size={650}
              title={`Hídrica - ${SIN.generation.meta.subsystem}`}
              type={EnergyLoadWidget.type.generation}
            />
            <InstantLoad
              value={SIN.generation.generation[SIN.generation.generation.length - 1]}
              upwardTrend={
                SIN.generation.generation[SIN.generation.generation.length - 1] >
                SIN.generation.generation[SIN.generation.generation.length - 2]
              }
              unit={SIN.generation.meta.units.generation}
              size={'small'}
              type={'Geração'}
              sub={SIN.generation.meta.subsystem}
              updated_at={SIN.generation.meta.updated_at}
            />
          </Grid>
          <Subsystems>
            {SUBS.filter((sub) => sub !== 'SIN').map((sub) => (
              <SubsystemItem key={sub}>
                <EnergyLoadWidget
                  data={dataLoad[sub].load}
                  size={446}
                  title={dataLoad[sub].load.meta.subsystem}
                  type={EnergyLoadWidget.type.load}
                />
                <InstantLoad
                  value={dataLoad[sub].load.load[dataLoad[sub].load.load.length - 1]}
                  upwardTrend={
                    dataLoad[sub].load.load[dataLoad[sub].load.load.length - 1] >
                    dataLoad[sub].load.load[dataLoad[sub].load.load.length - 2]
                  }
                  unit={dataLoad[sub].load.meta.units.load}
                  size={'xsmall'}
                  type={'Carga'}
                  sub={dataLoad[sub].load.meta.subsystem}
                  updated_at={dataLoad[sub].load.meta.updated_at}
                />
                <EnergyLoadWidget
                  data={dataLoad[sub].generation}
                  size={446}
                  title={`Hídrica - ${dataLoad[sub].generation.meta.subsystem}`}
                  type={EnergyLoadWidget.type.generation}
                />
                <InstantLoad
                  value={
                    dataLoad[sub].generation.generation[
                      dataLoad[sub].generation.generation.length - 1
                    ]
                  }
                  upwardTrend={
                    dataLoad[sub].generation.generation[
                      dataLoad[sub].generation.generation.length - 1
                    ] >
                    dataLoad[sub].generation.generation[
                      dataLoad[sub].generation.generation.length - 2
                    ]
                  }
                  unit={dataLoad[sub].generation.meta.units.generation}
                  size={'xsmall'}
                  type={'Geração'}
                  sub={dataLoad[sub].generation.meta.subsystem}
                  updated_at={dataLoad[sub].generation.meta.updated_at}
                />
              </SubsystemItem>
            ))}
            {/* <SubsystemItem>
              <EnergyLoadWidget
                data={dataLoad}
                size={446}
                title={`Carga - ${dataLoad.meta.subsystem}`}
                type={'Carga'}
              />
              <InstantLoad
                value={dataLoad.load[dataLoad.load.length - 1]}
                upwardTrend={
                  dataLoad.load[dataLoad.load.length - 1] >
                  dataLoad.load[dataLoad.load.length - 2]
                }
                unit={dataLoad.meta.units.load}
                size={'xsmall'}
                type={'Carga'}
                sub={dataLoad.meta.subsystem}
              />
              <EnergyLoadWidget
                data={dataGeneration}
                size={446}
                title={`Gercação Hídrica - ${dataGeneration.meta.subsystem}`}
                type={'Geração'}
              />
              <InstantLoad
                value={dataGeneration.generation[dataGeneration.generation.length - 1]}
                upwardTrend={
                  dataGeneration.generation[dataGeneration.generation.length - 1] >
                  dataGeneration.generation[dataGeneration.generation.length - 2]
                }
                unit={dataGeneration.meta.units.generation}
                size={'xsmall'}
                type={'Geração'}
                sub={dataGeneration.meta.subsystem}
              />
            </SubsystemItem>
            <SubsystemItem>
              <EnergyLoadWidget
                data={dataLoad}
                size={446}
                title={`Carga - ${dataLoad.meta.subsystem}`}
                type={'Carga'}
              />
              <InstantLoad
                value={dataLoad.load[dataLoad.load.length - 1]}
                upwardTrend={
                  dataLoad.load[dataLoad.load.length - 1] >
                  dataLoad.load[dataLoad.load.length - 2]
                }
                unit={dataLoad.meta.units.load}
                size={'xsmall'}
                type={'Carga'}
                sub={dataLoad.meta.subsystem}
              />
              <EnergyLoadWidget
                data={dataGeneration}
                size={446}
                title={`Gercação Hídrica - ${dataGeneration.meta.subsystem}`}
                type={'Geração'}
              />
              <InstantLoad
                value={dataGeneration.generation[dataGeneration.generation.length - 1]}
                upwardTrend={
                  dataGeneration.generation[dataGeneration.generation.length - 1] >
                  dataGeneration.generation[dataGeneration.generation.length - 2]
                }
                unit={dataGeneration.meta.units.generation}
                size={'xsmall'}
                type={'Geração'}
                sub={dataGeneration.meta.subsystem}
              />
            </SubsystemItem>*/}
          </Subsystems>
        </Content>
      )}
    </AuthenticatedPage>
  )
}
