import React, { useEffect, useRef, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { theme, prop } from 'styled-tools'

import { Text } from '@somarmeteorologia/momentum'
import { Content } from '@platform/components'
import { WeatherTable, WeatherChart, MultiModelChart } from '@platform/energy'

import { AuthenticatedPage } from '../containers'
import { UpdateAt, Loading } from '../components'
import { PAGE, GROUPING_LEVEL_ENA, MULTIMODEL } from '../config'
import { fetchMultiModel, fetchStationInfos } from '../services'
import { STATE } from '../helpers'

const Container = styled(Content)`
  overflow-y: auto;
  height: calc(100vh - 100px);
`

const Description = styled.div`
  width: 100%;
  background-color: ${theme('datatable.bg.primary')};
  line-height: 150%;
  border-radius: ${theme('border.radius.four')} ${theme('border.radius.four')} 0px 0px;
`

const TextWithLineHeight = styled(Text)`
  line-height: ${prop('lineHeight', 0)};
  width: 100%;
`

const WEATHER_MODEL = 'gfs'

const getGroupFetch = (group) => {
  const groups = {
    eer: 'ree',
    subsystem: 'sub',
    basin: 'basin',
    station: 'station'
  }

  return groups[group]
}

export const Weather = () => {
  const {
    setToOpen,
    setEER,
    setSubsystem,
    setBasin,
    setStations,
    setStationsInfos
  } = useStoreActions(({ config, weatherEnergy, energy }) => ({
    ...config,
    ...weatherEnergy,
    ...energy
  }))
  const [width, setWidth] = useState(1000)
  const [state, setState] = useState(STATE.loading)
  const [loadingStationInfos, setLoadingStationInfos] = useState(STATE.default)
  const [updateAt, setUpdateAt] = useState()
  const [data, setData] = useState()
  const [loadingModels, setLoadingModels] = useState([])
  const { eer, subsystem, basin, stations, selected, stationsInfos } = useStoreState(
    ({ energy, weatherEnergy, config }) => ({
      ...energy,
      ...weatherEnergy,
      ...config
    })
  )
  const refTable = useRef(null)
  const { text } = useContext(ThemeContext)

  const { groupingLevelSelected, domain } = selected

  const mapper = {
    eer: eer,
    subsystem: subsystem,
    basin: basin,
    station: stations
  }

  const mapperSet = {
    eer: setEER,
    subsystem: setSubsystem,
    basin: setBasin,
    station: setStations
  }

  useEffect(() => {
    let isMount = true

    setLoadingStationInfos(STATE.loading)
    if (groupingLevelSelected === GROUPING_LEVEL_ENA.station.id && !stationsInfos) {
      fetchStationInfos().then((infos) => {
        isMount && setStationsInfos(infos)
        isMount && setLoadingStationInfos(STATE.success)
      })
    } else {
      setLoadingStationInfos(STATE.success)
    }

    return () => {
      isMount = false
    }
  }, [groupingLevelSelected, setStationsInfos, stationsInfos])

  useEffect(() => {
    setToOpen(PAGE.weather.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isMount = true

    if (!mapper[groupingLevelSelected]) {
      setState(STATE.loading)
      let fetchs = []
      Object.entries(MULTIMODEL).forEach(([modelName]) => {
        if (!mapper[groupingLevelSelected] || !mapper[groupingLevelSelected][modelName]) {
          fetchs.push(
            fetchMultiModel(modelName, getGroupFetch(groupingLevelSelected)).then(
              (response) => {
                setLoadingModels((prevState) => [...prevState, modelName])
                return { [modelName]: response }
              }
            )
          )
        }
      })

      Promise.all(fetchs).then((models) => {
        const reduceModels = models.reduce((models, model) => {
          return { ...models, ...model }
        }, {})
        isMount && mapperSet[groupingLevelSelected](reduceModels)
        isMount && setData(reduceModels)
        isMount && setUpdateAt(reduceModels[WEATHER_MODEL]?.meta?.updated_at)
        isMount && setState(STATE.success)
      })
    } else if (mapper[groupingLevelSelected]) {
      isMount && setData(mapper[groupingLevelSelected])
      isMount &&
        setUpdateAt(mapper[groupingLevelSelected][WEATHER_MODEL]?.meta?.updated_at)
      isMount && setState(STATE.success)
    }

    return () => (isMount = false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basin, eer, groupingLevelSelected, stations, subsystem])

  useEffect(() => {
    setWidth(refTable?.current?.offsetWidth)
  }, [refTable, state])

  const getStationInfo = () => {
    if (stationsInfos) {
      const index = stationsInfos.stationId.indexOf(parseInt(domain))

      return `${stationsInfos.city[index]} ${stationsInfos.latitude[index]}, ${stationsInfos.longitude[index]}`
    }
  }

  const getStationName = () => {
    if (stationsInfos) {
      const index = stationsInfos.stationId.indexOf(parseInt(domain))

      return stationsInfos.name[index]
    }
  }

  return (
    <AuthenticatedPage>
      <UpdateAt date={updateAt} />
      {state === STATE.loading || loadingStationInfos === STATE.loading ? (
        <Loading
          message={`${loadingModels.length} modelo(s) carregados de ${
            Object.keys(MULTIMODEL).length
          }`}
        />
      ) : (
        <Container>
          <Text
            size={Text.size.sixteen}
            bottom={groupingLevelSelected === GROUPING_LEVEL_ENA.station.id ? 2 : 14}
          >
            {`Precipitação e Temperatura para ${
              GROUPING_LEVEL_ENA[groupingLevelSelected].name
            } ${
              groupingLevelSelected === GROUPING_LEVEL_ENA.station.id
                ? `${getStationName()} (${domain})`
                : domain
            }`}
          </Text>
          {groupingLevelSelected === GROUPING_LEVEL_ENA.station.id && (
            <TextWithLineHeight
              size={Text.size.twelve}
              weight={Text.weight.light}
              lineHeight={'150%'}
              color={text.quaternary}
              bottom={14}
            >
              {getStationInfo()}
            </TextWithLineHeight>
          )}
          <Description ref={refTable}>
            <Text size={Text.size.twelve} left={19} top={11} bottom={11}>
              PREVISTO
            </Text>
          </Description>
          <WeatherTable data={data[WEATHER_MODEL]} domain={domain} />
          <WeatherChart width={width} data={data[WEATHER_MODEL]} domain={domain} />
          <Text
            size={Text.size.sixteen}
            bottom={groupingLevelSelected === GROUPING_LEVEL_ENA.station.id ? 2 : 14}
            top={42}
          >
            {`Multimodel para ${GROUPING_LEVEL_ENA[groupingLevelSelected].name} ${
              groupingLevelSelected === GROUPING_LEVEL_ENA.station.id
                ? `${getStationName()} (${domain})`
                : domain
            }`}
          </Text>
          {groupingLevelSelected === GROUPING_LEVEL_ENA.station.id && (
            <TextWithLineHeight
              size={Text.size.twelve}
              weight={Text.weight.light}
              lineHeight={'150%'}
              color={text.quaternary}
              bottom={14}
            >
              {getStationInfo()}
            </TextWithLineHeight>
          )}
          <MultiModelChart
            models={data}
            domain={domain}
            groupLevel={groupingLevelSelected}
            width={width}
          />
        </Container>
      )}
    </AuthenticatedPage>
  )
}
