import { Env } from '../environment'
import { toJSON } from '../helpers'

const buildTenantRegistered = ({
  organization,
  accountName,
  legalName,
  stateId,
  document,
  user
}) => ({
  organization: `${organization}.platform.somar.io`,
  tier: 'Basic',
  accountType: 'company',
  accountName,
  legalName,
  stateId,
  document,
  user
})

const getError = (error) => {
  const errors = {
    'Incorrect username or password': 'Usuário ou senha inválida.',
    'This organization is already registered': 'Essa organização já existe.',
    'Invalid user': 'Verifique se o endereço de e-mail está correto.'
  }

  return errors[error] ?? 'Erro desconhecido, tente novamente'
}

const hasError = (body) => {
  if (body.error_message) {
    throw getError(body.error_message)
  }

  return body
}

export const tenantRegister = (body) =>
  fetch(`${Env.getEnv(Env.AUTH_URI)}/tenant/register`, {
    method: 'POST',
    body: JSON.stringify(buildTenantRegistered(body))
  })
    .then(toJSON)
    .then(hasError)

export const signin = (body) =>
  fetch(`${Env.getEnv(Env.AUTH_URI)}/auth`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then(toJSON)
    .then(hasError)

export const challengeNewPassword = (body) =>
  fetch(`${Env.getEnv(Env.AUTH_URI)}/auth/challenge/password`, {
    method: 'POST',
    body: JSON.stringify(body)
  }).then(toJSON)

export const getUserData = ({ access_token }) =>
  fetch(`${Env.getEnv(Env.AUTH_URI)}/user`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    method: 'GET'
  })
    .then(toJSON)
    .then(hasError)

export const getNewAccessToken = ({ refresh_token, access_token }) =>
  fetch(`${Env.getEnv(Env.AUTH_URI)}/auth/refresh`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    method: 'POST',
    body: JSON.stringify({ refreshToken: refresh_token })
  })
    .then(toJSON)
    .then(hasError)

export const sendRecoveryEmail = ({ email, organization }) =>
  fetch(
    `${Env.getEnv(
      Env.AUTH_URI
    )}/recover/code?email=${email}&organization=${organization}`,
    {
      method: 'GET'
    }
  )
    .then(toJSON)
    .then(hasError)

export const sendConfirmationCode = (body) =>
  fetch(`${Env.getEnv(Env.AUTH_URI)}/recover/code`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then(toJSON)
    .then(hasError)
