import React, { memo, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useStoreActions } from 'easy-peasy'

import { Input, Notification, Text, Icon } from '@somarmeteorologia/momentum'

import { sendRecoveryEmail } from '../services'
import { AuthenticableInterface } from '../containers'
import { getError, getTenant, STATE } from '../helpers'
import { Loading } from '../components'
import { equals } from 'ramda'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Formato inválido').required('Obrigatório')
})

const initialValues = {
  email: ''
}

export const Recovery = memo(({ history }) => {
  const [state, setState] = useState(STATE.default)
  const [message, setMessage] = useState('')

  const { setEmail, setOrganization } = useStoreActions(({ user }) => user)

  const { handleChange, values, errors, validateForm, isValid } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema
  })

  const { email } = values

  const onClick = () => {
    setState(STATE.loading)
    validateForm()

    if (isValid) {
      const organization = `${getTenant()}.platform.somar.io`

      sendRecoveryEmail({ email, organization })
        .then(() => {
          setEmail(email)
          setOrganization(organization)
          setState(STATE.success)
          history.push('/code')
        })
        .catch((error) => {
          setState(STATE.error)
          setMessage(error)
        })
    }
  }

  const getWithErrors = getError(errors)

  return (
    <>
      {equals(state, STATE.loading) && <Loading />}

      <Notification
        icon={({ color }) => <Icon name="disabled" color={color} />}
        state={Notification.state.danger}
        isOpen={equals(state, STATE.error)}
        toClose={() => setState(STATE.default)}
        title={({ color }) => (
          <Text.Heading size={Text.Heading.size.sixteen} color={color}>
            Algo de errado aconteceu
          </Text.Heading>
        )}
        description={({ color }) => (
          <Text size={Text.size.thirteen} color={color}>
            {message}
          </Text>
        )}
      />

      <AuthenticableInterface onClick={onClick} type="recovery">
        <Input
          full={true}
          raw={true}
          id="email"
          label="Usuário"
          placeholder="Utilize seu email corporativo"
          value={email}
          onChange={handleChange}
          error={getWithErrors('email')}
        />
      </AuthenticableInterface>
    </>
  )
})
