import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as csDictionary from 'plotly.js/lib/locales/pt-br.js'
import { buildDataTable } from './utils'

const config = { displayModeBar: false }

export function WeatherChart({ width, data, domain }) {
  const {
    datatable,
    colors,
    text,
    font: {
      family: { roboto }
    }
  } = useContext(ThemeContext)
  Plotly.register(csDictionary)
  const Plot = createPlotlyComponent(Plotly)

  const { periods, precipitation, temperature } = buildDataTable(data, domain)

  return (
    <Plot
      data={[
        {
          text: precipitation.map((value) => `${value.toString().replace('.', ',')}mm`),
          textposition: 'auto',
          hoverinfo: 'none',
          x: periods,
          y: precipitation,
          type: 'bar',
          textfont: {
            color: text.primary,
            family: roboto,
            size: 11
          },
          marker: {
            color: colors.cornflower.fifty,
            line: {
              color: 'rgba(255,255,255, 0.5)',
              width: 1.5
            }
          }
        },
        {
          text: temperature.map((value) => `${value.toString().replace('.', ',')}°C`),
          textposition: 'top center',
          hoverinfo: 'none',
          x: periods,
          y: temperature,
          type: 'scatter',
          mode: 'lines+markers+text',
          yaxis: 'y2',
          textfont: {
            color: text.primary,
            family: roboto,
            size: 11
          },
          marker: {
            size: 8,
            symbol: 'circle',
            color: '#F00',
            line: {
              color: '#FfF',
              width: 2
            }
          }
        }
      ]}
      layout={{
        plot_bgcolor: datatable.bg.primary,
        paper_bgcolor: datatable.bg.primary,
        yaxis2: {
          range: [0, 45],
          tickmode: 'array',
          tickvals: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45],
          ticktext: [
            '0°C',
            '5°C',
            '10°C',
            '15°C',
            '20°C',
            '25°C',
            '30°C',
            '35°C',
            '40°C',
            '45°C'
          ],
          overlaying: 'y',
          side: 'right',
          color: text.primary,
          fixedrange: true,
          tickfont: { family: roboto }
        },
        xaxis: {
          showticklabels: false,
          fixedrange: true
        },
        yaxis: {
          tickmode: 'array',
          range: [0, 60],
          tickvals: [0, 10, 20, 30, 40, 50, 60],
          ticktext: ['0mm', '10mm', '20mm', '30mm', '40mm', '50mm', '60mm'],
          color: text.primary,
          fixedrange: true,
          tickfont: { family: roboto }
        },
        showlegend: false,
        margin: {
          t: 15,
          b: 15,
          r: 51,
          l: 51
        },
        width,
        height: 300
      }}
      config={config}
    />
  )
}
