import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Reset, Theme } from '@somarmeteorologia/momentum'
import { StoreProvider } from 'easy-peasy'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import updateLocale from 'dayjs/plugin/updateLocale'
import toObject from 'dayjs/plugin/toObject'

import Router from './Router'
import { store } from './store'

dayjs.locale('pt-br')
dayjs.extend(updateLocale)
dayjs.extend(toObject)
dayjs.updateLocale('pt-br', {
  weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
})

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={Theme.dark}>
      <Reset />
      <Router />
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
)
