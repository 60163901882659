import React, { memo } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { withRouter } from 'react-router-dom'

import { Navigation } from '../Navigation'

import logo from '../../assets/images/LOGO_ENERGIA_SOMAR.svg'

const Container = styled.div`
  width: 270px;
`

const Organization = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: ${theme('bg.secondary')};
`
const Logo = styled.img`
  width: 250px;
`

export const SideMenu = withRouter(
  memo(({ history }) => {
    return (
      <Container>
        <Organization
          onClick={() => {
            history.push('/specialist')
          }}
        >
          <Logo src={logo} />
        </Organization>
        <Navigation />
      </Container>
    )
  })
)
