import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import dayjs from 'dayjs'

import { Widget } from '@platform/components'

import { Text, Icon } from '@somarmeteorologia/momentum'

const Container = styled.div`
  padding: 25px 0px 10px 18px;
  height: 235px;
  display: flex;
  flex-direction: column;
`

const Data = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
`
const Value = styled(Text)`
  line-height: 140%;
`

const Measure = styled(Text)`
  line-height: 150%;
`

export function InstantLoad({ value, upwardTrend, unit, size, type, sub, updated_at }) {
  const {
    text,
    colors: { green, red }
  } = useContext(ThemeContext)

  const layout = {
    title: `Instantâneo ${type} - ${sub}`,
    content: () => (
      <Container>
        <Data>
          <Value size={Text.size.twentyEight} family={Text.family.roboto} right={12}>
            {value.toFixed(1)}
          </Value>
          <Measure
            size={Text.size.sixteen}
            family={Text.family.roboto}
            color={text.disabled}
            right={10}
          >
            {unit}
          </Measure>
          <Icon
            name={upwardTrend ? 'up' : 'down'}
            color={upwardTrend ? green.fifty : red.fifty}
          />
        </Data>
        <Text top={60} size={Text.size.twelve}>
          Fonte: ONS
        </Text>
      </Container>
    ),
    footer: () => (
      <Text size={Text.size.thirteen} left={25} top={18} bottom={16} right={13}>
        {` ${dayjs(dayjs(updated_at).add(dayjs().utcOffset(), 'minute')).format(
          'DD [de] MMM [às] HH:mm'
        )}`}
      </Text>
    )
  }

  return <Widget layout={layout} size={Widget.size[size]} />
}
