import React, { memo, useState, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { theme } from 'styled-tools'
import ReactCodeInput from 'react-verification-code-input'
import { includes, equals } from 'ramda'
import * as Yup from 'yup'
import { useStoreState } from 'easy-peasy'
import { useFormik } from 'formik'

import { Text, Input } from '@somarmeteorologia/momentum'

import { AuthenticableInterface } from '../containers'
import { Loading, Feedback } from '../components'
import { sendConfirmationCode } from '../services'
import { getError } from '../helpers'

const CodeInput = styled(ReactCodeInput)`
  width: 100% !important;

  & > div {
    display: flex;
  }

  input {
    margin: 0 6px;
    width: 45px !important;
    height: 45px !important;
    border: ${theme('field.border.primary')};
    border-radius: ${theme('border.radius.four')};
    color: ${theme('field.text.primary')};
    background-color: ${theme('field.bg.primary')};
    font-family: ${theme('font.family.inter')};

    &:focus {
      border: ${theme('field.border.secondary')};
    }
  }

  input:last-child {
    border-right: ${theme('field.border.primary')};
  }
`

const STEPS = {
  CONFIRM_CODE: 'CONFIRM_CODE',
  NEW_PASSWORD: 'NEW_PASSWORD',
  PASSWORD_REDEFINED: 'PASSWORD_REDEFINED',
  WRONG_CODE: 'WRONG_CODE',
  LOADING: 'LOADING',
  ERROR: 'ERROR'
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Mínimo de 8 caracteres')
    .required('Obrigatório')
    .matches(/\w*\d+\w*/, 'Obrigatório ao menos um dígito (0-9)')
    .matches(/\w*[A-Z]+\w*/, 'Obrigatório ao menos uma letra maiúscula')
    .matches(/\w*[a-z]+\w*/, 'Obrigatório ao menos uma letra minúscula'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
    .required('É obrigatório confirmar a senha')
})

const initialValues = {
  password: '',
  passwordConfirmation: ''
}

const types = {
  CONFIRM_CODE: 'code',
  NEW_PASSWORD: 'new_password',
  PASSWORD_REDEFINED: 'feedback_password'
}

export const Code = memo(({ history }) => {
  const [code, setCode] = useState('')
  const [step, setStep] = useState(STEPS.CONFIRM_CODE)
  const { text } = useContext(ThemeContext)
  const { email, organization } = useStoreState(({ user }) => user.data)
  const { handleChange, values, isValid, errors, validateForm } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    validateOnChange: true,
    validationSchema
  })

  const { password, passwordConfirmation } = values

  const onNewPasswordRequested = () => {
    validateForm()

    setStep(STEPS.LOADING)

    isValid &&
      sendConfirmationCode({
        password,
        passwordConfirmation,
        confirmationCode: code,
        email,
        organization
      })
        .then(() => setStep(STEPS.PASSWORD_REDEFINED))
        .catch(() => setStep(STEPS.WRONG_CODE))
  }

  const hasAllFilled = () => code.length === 6

  const onCodeConfirmed = () => setStep(STEPS.NEW_PASSWORD)

  const clicks = {
    CONFIRM_CODE: onCodeConfirmed,
    NEW_PASSWORD: onNewPasswordRequested,
    PASSWORD_REDEFINED: () => history.push('/')
  }

  const isAtConfirmation = includes(step, [STEPS.CONFIRM_CODE, STEPS.WRONG_CODE])

  const isAtNewPassword = includes(step, [STEPS.NEW_PASSWORD, STEPS.LOADING])

  const getWithErrors = getError(errors)

  return (
    <>
      {equals(step, STEPS.LOADING) && <Loading />}
      <AuthenticableInterface
        type={types[step]}
        disabled={!hasAllFilled()}
        onClick={clicks[step]}
      >
        {isAtNewPassword && (
          <>
            <Text
              size={Text.size.twentyEight}
              color={text.primary}
              weight="bold"
              bottom={10}
            >
              Redefinir senha
            </Text>

            <Input
              full={true}
              raw={true}
              id="password"
              type="password"
              label="Nova senha"
              placeholder="Digite sua senha"
              value={password}
              onChange={handleChange}
              error={getWithErrors('password')}
            />

            <Input
              full={true}
              raw={true}
              id="passwordConfirmation"
              type="password"
              label="Confirmar senha"
              placeholder="Digite sua senha novamente"
              value={passwordConfirmation}
              onChange={handleChange}
              error={getWithErrors('passwordConfirmation')}
            />
          </>
        )}

        {isAtConfirmation && (
          <>
            <Text size={Text.size.fourteen} color={text.secondary} bottom={10}>
              Digite o código que enviamos para seu e-mail no campo abaixo.
            </Text>
            <CodeInput className="container" onChange={setCode} />
          </>
        )}

        {equals(step, STEPS.PASSWORD_REDEFINED) && (
          <Feedback type="redefined" onClick={() => history.push('/')} />
        )}
      </AuthenticableInterface>
    </>
  )
})
