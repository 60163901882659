import { Env } from '../environment'
import { toJSON } from '../helpers'

const { getEnv, KRAKEN_URI, KRAKEN_KEY } = Env

const defaultConfig = {
  headers: { 'x-api-key': getEnv(KRAKEN_KEY), 'Content-Type': 'application/json' }
}

const URLbyModel = (model, group) =>
  `${getEnv(KRAKEN_URI)}/multimodel/${model}/${group}/daily`

export const fetchDomains = async () => {
  return await fetch(`${getEnv(KRAKEN_URI)}/domains`, defaultConfig).then(toJSON)
}

export const fetchEER = async () => {
  return await Promise.all([
    fetch(`${getEnv(KRAKEN_URI)}/full/ree/monthly`, defaultConfig).then(toJSON),
    fetch(`${getEnv(KRAKEN_URI)}/full/ree/weekly`, defaultConfig).then(toJSON)
  ]).then((responseJSON) => {
    return { monthly: responseJSON[0], weekly: responseJSON[1] }
  })
}

export const fetchSubsystem = async () => {
  return await Promise.all([
    fetch(`${getEnv(KRAKEN_URI)}/full/sub/monthly`, defaultConfig).then(toJSON),
    fetch(`${getEnv(KRAKEN_URI)}/full/sub/weekly`, defaultConfig).then(toJSON)
  ]).then((responseJSON) => {
    return { monthly: responseJSON[0], weekly: responseJSON[1] }
  })
}

export const fetchBasin = async () => {
  return await Promise.all([
    fetch(`${getEnv(KRAKEN_URI)}/full/basin/monthly`, defaultConfig).then(toJSON),
    fetch(`${getEnv(KRAKEN_URI)}/full/basin/weekly`, defaultConfig).then(toJSON)
  ]).then((responseJSON) => {
    return { monthly: responseJSON[0], weekly: responseJSON[1] }
  })
}

export const fetchStation = async () => {
  return await Promise.all([
    fetch(`${getEnv(KRAKEN_URI)}/full/station/monthly`, defaultConfig).then(toJSON),
    fetch(`${getEnv(KRAKEN_URI)}/full/station/weekly`, defaultConfig).then(toJSON)
  ]).then((responseJSON) => {
    return { monthly: responseJSON[0], weekly: responseJSON[1] }
  })
}

export const fetchStationInfos = async () => {
  return await fetch(`${getEnv(KRAKEN_URI)}/stations/all`, defaultConfig)
    .then(toJSON)
    .then((response) => response)
}

export const fetchMultiModel = (model, groupLevel) => {
  return fetch(`${URLbyModel(model, groupLevel)}`, defaultConfig).then(toJSON)
}

export const fetchEnergyLoad = (sub) => {
  return fetch(`${getEnv(KRAKEN_URI)}/load/${sub}`, defaultConfig).then(toJSON)
}

export const fetchEnergyGeneration = (sub, type) => {
  return fetch(`${getEnv(KRAKEN_URI)}/generation/${sub}/${type}`, defaultConfig).then(
    toJSON
  )
}
