import React /*, { useContext }*/ from 'react'
import { withRouter } from 'react-router-dom'
//import styled, { ThemeContext } from 'styled-components'

import {
  Text,
  Icon,
  Navigation,
  Radio,
  Autocomplete,
  Select //,
  //Switch,
  //Tooltip,
  //Range,
  //Box
} from '@somarmeteorologia/momentum'

import { GROUPING_LEVEL_ENA, ENA_FREQUENCY, ENA_HISTORY_OPTIONS } from '../../config'

const { Type, Title, Separator /*, Control*/ } = Navigation

const getLocationTypeENA = () => {
  return [
    ...Object.values(GROUPING_LEVEL_ENA).map(({ id, name, icon }) => ({
      id,
      text: name,
      selected: id === GROUPING_LEVEL_ENA.basin.id,
      icon: ({ width, height, color }) => (
        <Icon name={icon} width={width} height={height} color={color} />
      )
    }))
  ]
}

const Structure = ({ children, history }) => {
  const structure = (history) => [
    {
      id: 'energy',
      type: Type.Category,
      title: ({ details, description }) => (
        <>
          <Icon
            name="lightningCircle"
            right={10}
            width={20}
            height={20}
            color={details}
          />
          <Text weight={Text.weight.bold} size={Text.size.fourteen} color={description}>
            Energia
          </Text>
        </>
      ),
      children: [
        {
          id: 'painel',
          parent: 'energy',
          onBack: () => history.push('/specialist'),
          onClick: () => history.push('/painel'),
          type: Type.Group,
          title: ({ details }) => (
            <Title>
              <Icon name="chart" right={10} width={20} height={20} color={details} />
              <Text size={Text.size.fourteen}>Painel</Text>
            </Title>
          ),
          children: []
        },
        {
          id: 'waterForecast',
          parent: 'energy',
          onBack: () => history.push('/painel'),
          onClick: () => history.push('/water-forecast'),
          type: Type.Group,
          title: ({ details }) => (
            <Title>
              <Icon name="newsletter" right={10} width={20} height={20} color={details} />
              <Text size={Text.size.fourteen}>Previsão Hidrológica</Text>
            </Title>
          ),
          children: [
            {
              id: 'location',
              parent: 'waterForecast',
              type: Type.Item,
              title: ({ getter, setter }) => {
                return (
                  <>
                    <Text bottom={15} size={Text.size.fourteen} weight={Text.weight.bold}>
                      Local
                    </Text>

                    <Autocomplete
                      full={true}
                      categories={getLocationTypeENA()}
                      options={getter('options')}
                      onChange={({ value, category }) => {
                        setter('selected', {
                          domain: value,
                          groupingLevelSelected: category
                        })
                      }}
                    />
                  </>
                )
              },
              children: []
            },
            {
              id: 'frequency',
              parent: 'waterForecast',
              type: Type.Item,
              title: ({ setter }) => (
                <>
                  <Text
                    top={15}
                    bottom={15}
                    size={Text.size.twelve}
                    weight={Text.weight.bold}
                  >
                    Frequência
                  </Text>

                  <Radio
                    name="frequency"
                    labelAlign={Radio.labelAlign.right}
                    size={Radio.size.small}
                    orientation={Radio.orientation.column}
                    onChange={(value) => {
                      setter('frequencySelected', value)
                    }}
                  >
                    {ENA_FREQUENCY.map(({ id, label }) => (
                      <Radio.Option
                        key={id}
                        id={id}
                        label={label}
                        bottom={15}
                        checked={id === 'monthly'}
                      />
                    ))}
                  </Radio>
                </>
              ),
              children: []
            },
            {
              id: 'period',
              parent: 'waterForecast',
              type: Type.Item,
              title: ({ setter }) => (
                <>
                  <Text
                    top={15}
                    bottom={15}
                    size={Text.size.fourteen}
                    weight={Text.weight.bold}
                  >
                    Histórico
                  </Text>

                  <Select
                    onChange={(value) => {
                      setter(
                        'history',
                        ENA_HISTORY_OPTIONS.find((option) => option.text === value).value
                      )
                    }}
                    options={ENA_HISTORY_OPTIONS}
                    defaultValue={'3 anos'}
                  />
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'weather',
          parent: 'energy',
          onBack: () => history.push('/painel'),
          onClick: () => history.push('/weather'),
          type: Type.Group,
          title: ({ details }) => (
            <Title>
              <Icon name="barChart" right={10} width={20} height={20} color={details} />
              <Text size={Text.size.fourteen}>Previsão do Tempo</Text>
            </Title>
          ),
          children: [
            {
              id: 'weatherMap',
              parent: 'weather',
              onBack: () => history.push('/weather'),
              onClick: () => history.push('/weather/map'),
              type: Type.Group,
              title: ({ details }) => (
                <Title>
                  <Icon name="map" right={10} width={20} height={20} color={details} />
                  <Text size={Text.size.fourteen}>Mapas</Text>
                </Title>
              ),
              children: [
                {
                  id: 'optionModel',
                  parent: 'weatherMap',
                  type: Type.Item,
                  title: ({ getter, setter }) => (
                    <>
                      <Text
                        top={15}
                        bottom={15}
                        size={Text.size.twelve}
                        weight={Text.weight.bold}
                      >
                        Modelos
                      </Text>
                      <Radio
                        name="models"
                        labelAlign={Radio.labelAlign.right}
                        size={Radio.size.small}
                        orientation={Radio.orientation.column}
                        onChange={(value) => setter('model', value)}
                      >
                        <Radio.Option
                          id="gfs"
                          label="GFS"
                          bottom={10}
                          checked={getter('model') === 'gfs'}
                        />
                        <Radio.Option
                          id="cfs"
                          label="CFS"
                          bottom={10}
                          checked={getter('model') === 'cfs'}
                        />
                        <Radio.Option
                          id="somar"
                          label="Somar"
                          bottom={10}
                          checked={getter('model') === 'Somar'}
                        />
                      </Radio>
                      <Separator />
                    </>
                  ),
                  children: []
                },
                {
                  id: 'variable',
                  parent: 'weatherMap',
                  type: Type.Item,
                  title: ({ getter, setter }) => (
                    <>
                      <Text
                        top={15}
                        bottom={15}
                        size={Text.size.twelve}
                        weight={Text.weight.bold}
                      >
                        Variável
                      </Text>
                      <Radio
                        name="variableMap"
                        labelAlign={Radio.labelAlign.right}
                        size={Radio.size.small}
                        orientation={Radio.orientation.column}
                        onChange={(value) => setter('variableMap', value)}
                      >
                        <Radio.Option
                          id="tmin"
                          label="Temperatura Mínima"
                          bottom={10}
                          checked={getter('variableMap') === 'tmin'}
                        />
                        <Radio.Option
                          id="desvtmin"
                          label="Desvio Temperatura Mínima"
                          bottom={10}
                          checked={getter('variableMap') === 'desvtmin'}
                        />
                        <Radio.Option
                          id="tmax"
                          label="Temperatura Máxima"
                          bottom={10}
                          checked={getter('variableMap') === 'tmax'}
                        />
                        <Radio.Option
                          id="desvtmax"
                          label="Desvio Temperatura Máxima"
                          bottom={10}
                          checked={getter('variableMap') === 'desvtmax'}
                        />
                        <Radio.Option
                          id="prec"
                          label="Precipitação"
                          bottom={10}
                          checked={getter('variableMap') === 'prec'}
                        />
                        <Radio.Option
                          id="desvprec"
                          label="Desvio Precipitação"
                          bottom={10}
                          checked={getter('variableMap') === 'desvprec'}
                        />
                      </Radio>
                      <Separator />
                    </>
                  ),
                  children: []
                },
                {
                  id: 'frequencyMap',
                  parent: 'weatherMap',
                  type: Type.Item,
                  title: ({ setter }) => (
                    <>
                      <Text
                        top={15}
                        bottom={15}
                        size={Text.size.twelve}
                        weight={Text.weight.bold}
                      >
                        Frequência
                      </Text>

                      <Select
                        options={[
                          { value: 'day', text: 'Diário' },
                          { value: 'week', text: 'Semanal' }
                        ]}
                        defaultValue={'Diário'}
                        onChange={(value) => setter('typeMap', value)}
                      />

                      <Separator />
                    </>
                  ),
                  children: []
                }
              ]
            },
            {
              id: 'location',
              parent: 'weather',
              type: Type.Item,
              title: ({ getter, setter }) => {
                return (
                  <>
                    <Text bottom={15} size={Text.size.fourteen} weight={Text.weight.bold}>
                      Local
                    </Text>

                    <Autocomplete
                      full={true}
                      categories={getLocationTypeENA()}
                      options={getter('options')}
                      onChange={({ value, category }) => {
                        setter('selected', {
                          domain: value,
                          groupingLevelSelected: category
                        })
                      }}
                    />
                  </>
                )
              },
              children: []
            }
          ]
        }
      ]
    }
  ]

  return <>{children({ structure: structure(history) })}</>
}

export default withRouter(Structure)
