import React, { useContext, useEffect, memo } from 'react'
import { useStoreActions } from 'easy-peasy'

import { Navigation } from '@somarmeteorologia/momentum'

import { fetchDomains } from '../../services'
import { GROUPING_LEVEL_ENA } from '../../config'

const { Context, useInterable } = Navigation

const getOptions = (options, groupLevel) => {
  let arr = []

  for (let index = 0; index < options.length; index++) {
    const option = options[index]
    arr.push({
      value: option,
      text: option,
      category: groupLevel
    })
  }

  return arr
}

const buildOptions = (domains) => {
  const { subs, rees, basins, stations } = domains
  let options = []

  // console.log(...getOptions(subs, GROUPING_LEVEL_ENA.subsystem.id))

  options.push(...getOptions(subs, GROUPING_LEVEL_ENA.subsystem.id))
  options.push(...getOptions(rees, GROUPING_LEVEL_ENA.eer.id))
  options.push(...getOptions(basins, GROUPING_LEVEL_ENA.basin.id))
  options.push(...getOptions(stations, GROUPING_LEVEL_ENA.station.id))

  return options
}

const External = memo(({ children }) => {
  const { setLoading } = useStoreActions(
    ({ waterForecast, config, weatherEnergy, energy }) => ({
      ...waterForecast,
      ...config,
      ...weatherEnergy.models,
      ...energy
    })
  )
  const interable = useContext(Context)
  const withInterable = useInterable(interable)
  const [setLocation] = withInterable('location')

  useEffect(() => {
    let isMounted = true
    const getData = () => {
      setLoading(true)
      fetchDomains().then((domains) => {
        isMounted && setLocation('options', buildOptions(domains))
        isMounted && setLoading(false)
      })
    }

    getData()
    return () => (isMounted = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
})

export default External
