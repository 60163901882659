import React, { memo, useEffect } from 'react'
import { Input } from '@somarmeteorologia/momentum'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { getError } from '../../../helpers'
import { STEPS } from '..'

const validationSchema = Yup.object().shape({
  givenName: Yup.string().required('Obrigatório'),
  username: Yup.string().required('Obrigatório'),
  email: Yup.string().email('Formato inválido').required('Obrigatório')
})

const initialValues = {
  givenName: '',
  email: '',
  username: ''
}

export const User = memo(({ onChange }) => {
  const { handleChange, values, errors, validateForm } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema
  })

  const { email, givenName, username } = values

  const getWithErrors = getError(errors)

  useEffect(() => {
    onChange({ type: STEPS.user, values, validateForm })
  }, [values, validateForm, onChange])

  return (
    <>
      <Input
        data-testid="givenName"
        full={true}
        raw={true}
        id="givenName"
        label="Nome completo"
        placeholder="Digite seu nome completo"
        value={givenName}
        onChange={handleChange}
        error={getWithErrors('givenName')}
      />

      <Input
        data-testid="email"
        full={true}
        raw={true}
        id="email"
        label="Email corporativo"
        placeholder="Utilize seu email corporativo"
        value={email}
        onChange={handleChange}
        error={getWithErrors('email')}
      />

      <Input
        data-testid="username"
        full={true}
        raw={true}
        id="username"
        label="Usuário"
        placeholder="Utilize um nome de usuário"
        value={username}
        onChange={handleChange}
        error={getWithErrors('username')}
      />
    </>
  )
})
