import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { evolve, always } from 'ramda'
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as csDictionary from 'plotly.js/lib/locales/pt-br.js'

import { buildDataChartMultiModel } from './utils'

const config = {
  locale: 'pt-br'
}

const Container = styled.div`
  .js-plotly-plot {
    margin-bottom: -3px;
  }
`

export function MultiModelChart({ width, models, domain }) {
  const {
    bg,
    colors,
    text,
    font,
    font: {
      family: { roboto, inter }
    }
  } = useContext(ThemeContext)
  Plotly.register(csDictionary)
  const Plot = createPlotlyComponent(Plotly)

  const { temperature, precipitation } = buildDataChartMultiModel(models, domain)

  const layout = {
    separators: ',',
    dragmode: 'zoom',
    plot_bgcolor: bg.secondary,
    paper_bgcolor: bg.secondary,
    xaxis: {
      title: 'Data',
      tickformat: '%A <br> %d %B',
      color: text.primary
    },
    yaxis: {
      title: { text: 'Temperatura (Cº)', font: { color: 'rgba(152, 161, 194, 0.6)' } },
      color: text.primary,
      tickfont: { family: roboto }
    },
    legend: {
      y: -0.25,
      orientation: 'h',
      xanchor: 'center',
      x: 0.5,
      font: { color: text.primary, font: inter }
    },
    height: 600,
    width,
    margin: {
      t: 40,
      b: 10,
      l: 60
    },
    hovermode: 'x unified',
    hoverlabel: {
      bgcolor: colors.white.hundred,
      bordercolor: '#E4E8ED',
      font: {
        family: inter,
        size: font.size.ten,
        color: colors.ebony.zero
      }
    }
  }

  return (
    <Container>
      <Plot data={temperature} layout={layout} config={config} />
      <Plot
        data={precipitation}
        layout={evolve(
          {
            yaxis: {
              title: { text: always('Precipitação (mm)') }
            }
          },
          layout
        )}
        config={config}
      />
    </Container>
  )
}
