import React, { memo, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Input, Text, Notification, Icon } from '@somarmeteorologia/momentum'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { AuthenticableInterface } from '../containers'
import { getError } from '../helpers'
import { challengeNewPassword } from '../services'
import dayjs from 'dayjs'
import { not } from 'ramda'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Mínimo de 8 caracteres')
    .required('Obrigatório')
    .matches(/\w*\d+\w*/, 'Obrigatório ao menos um dígito (0-9)')
    .matches(/\w*[A-Z]+\w*/, 'Obrigatório ao menos uma letra maiúscula')
    .matches(/\w*[a-z]+\w*/, 'Obrigatório ao menos uma letra minúscula'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
    .required('É obrigatório confirmar a senha')
})

const initialValues = {
  password: '',
  passwordConfirmation: ''
}

export const Challenge = memo(({ history }) => {
  const [hasNotification, setHasNotification] = useState(true)
  const { session, email, organization, challenge } = useStoreState(
    ({ user }) => user.data
  )
  const setUser = useStoreActions(({ user }) => user.set)

  const { handleChange, values, isValid, errors, validateForm } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    validateOnChange: true,
    validationSchema
  })

  const { password, passwordConfirmation } = values

  const isChallengeNewPassword = (challenge) => challenge === 'NEW_PASSWORD_REQUIRED'

  useEffect(() => {
    not(isChallengeNewPassword(challenge)) && history && history.push('/')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    validateForm()

    isValid &&
      challengeNewPassword({
        session,
        username: email,
        password,
        passwordConfirmation,
        organization
      }).then(({ access_token, expires_in, refresh_token }) => {
        setUser({
          email,
          password,
          session,
          organization,
          challenge: '',
          access_token,
          refresh_token,
          expires_in: dayjs().add(expires_in - 120, 's')
        })

        access_token && history.push('/specialist')
      })
  }

  const getWithErrors = getError(errors)

  return (
    <>
      <Notification
        icon={({ color }) => <Icon name="disabled" color={color} />}
        state={Notification.state.primary}
        isOpen={hasNotification}
        toClose={() => setHasNotification(false)}
        title={({ color }) => (
          <Text.Heading size={Text.Heading.size.sixteen} color={color}>
            Siga a instrução
          </Text.Heading>
        )}
        description={({ color }) => (
          <Text size={Text.size.thirteen} color={color}>
            É necessário que você altere a senha recebida por e-mail para uma nova por
            questões de segurança.
          </Text>
        )}
      />

      <AuthenticableInterface type="challenge" onClick={onClick}>
        <Input
          full={true}
          raw={true}
          id="password"
          type="password"
          label="Nova senha"
          placeholder="Digite sua senha"
          value={password}
          onChange={handleChange}
          error={getWithErrors('password')}
        />

        <Input
          full={true}
          raw={true}
          id="passwordConfirmation"
          type="password"
          label="Confirmar senha"
          placeholder="Digite sua senha novamente"
          value={passwordConfirmation}
          onChange={handleChange}
          error={getWithErrors('passwordConfirmation')}
        />
      </AuthenticableInterface>
    </>
  )
})
