import React, { memo, useState } from 'react'
import { equals } from 'ramda'

import { Text, Notification, Icon } from '@somarmeteorologia/momentum'

import { AuthenticableInterface } from '../../containers'

import { User, Organization } from './steps'
import { tenantRegister } from '../../services'
import { STATE } from '../../helpers'
import { Loading } from '../../components'

export const STEPS = {
  user: 'user',
  organization: 'organization'
}

export const Signup = memo(({ history }) => {
  const [step, setStep] = useState(STEPS.user)
  const [config, setConfig] = useState({})
  const [user, setUser] = useState({})
  // const [registered, setRegistered] = useState({})
  const [state, setState] = useState(STATE.default)
  const [message, setMessage] = useState('')

  const { validateForm, values } = config

  const getStep = {
    user: {
      Component: User,
      type: 'nextSignup',
      onClick: () => {
        validateForm().then((errors) => {
          const isValid = Object.keys(errors).length === 0

          if (isValid) {
            setUser(values)
            setStep(STEPS.organization)
          }
        })
      }
    },
    organization: {
      Component: Organization,
      type: 'signup',
      onClick: () => {
        validateForm().then((errors) => {
          const isValid = Object.keys(errors).length === 0

          isValid && onIsValid()
        })
      }
    }
  }

  const onIsValid = () => {
    setState(STATE.loading)

    tenantRegister({ ...values, user })
      .then((registered) => {
        setState(STATE.default)
        // setRegistered(registered)
      })
      .catch((error) => {
        setState(STATE.error)
        setMessage(error)
      })
  }

  const { Component, type, onClick } = getStep[step]

  return (
    <>
      {equals(state, STATE.loading) && <Loading />}

      <Notification
        icon={({ color }) => <Icon name="disabled" color={color} />}
        state={Notification.state.danger}
        isOpen={equals(state, STATE.error)}
        toClose={() => setState(STATE.default)}
        duration={3}
        title={({ color }) => (
          <Text.Heading size={Text.Heading.size.sixteen} color={color}>
            Algo de errado aconteceu
          </Text.Heading>
        )}
        description={({ color }) => (
          <Text size={Text.size.thirteen} color={color}>
            {message}
          </Text>
        )}
      />

      <AuthenticableInterface onClick={onClick} type={type}>
        <Component onChange={setConfig} />
      </AuthenticableInterface>
    </>
  )
})
