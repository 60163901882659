import React from 'react'
import styled from 'styled-components'
import { theme, prop } from 'styled-tools'

import { Text } from '@somarmeteorologia/momentum'

const SIZE = {
  xlarge: '1150px',
  large: '650px',
  medium: '446px',
  small: '300px',
  xsmall: '270px'
}

const Container = styled.div`
  background-color: ${theme('bg.secondary')};
  border-radius: ${theme('border.radius.four')};
  width: ${prop('size', '650px')};
`
const Header = styled.div`
  line-height: 18px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
`

const Body = styled.div`
  border-top: 1px solid ${theme('text.primary')}1;
`

const Footer = styled.div`
  background-color: ${theme('bg.tertiary')};
  border-radius: 0 0 ${theme('border.radius.four')} ${theme('border.radius.four')};
  line-height: 16px;
  display: flex;
  /* height: 50px; */
`

export function Widget({ layout, size }) {
  const { title, content, footer } = layout

  return (
    <Container size={size}>
      <Header>
        <Text
          size={Text.size.fourteen}
          left={25}
          top={18}
          bottom={18}
          weight={Text.weight.bold}
        >
          {title}
        </Text>
      </Header>
      <Body>{content()}</Body>
      <Footer>{footer()}</Footer>
    </Container>
  )
}

Widget.size = SIZE
