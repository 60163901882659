import { useStoreActions } from 'easy-peasy'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { AuthenticatedPage } from '../../containers'
import { PAGE } from '../../config'

const Content = styled.div`
  color: ${theme('text.primary')};
  font-family: ${theme('font.family.inter')};
  padding: 25px 150px;
  height: calc(100vh - 70px);
  overflow: auto;

  figcaption {
    font-size: 12px;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 10px;
  }
`

export function Details({ post, history, location }) {
  const { setToOpen } = useStoreActions(({ config }) => config)

  const { state } = location

  useEffect(() => {
    setToOpen(PAGE.painel.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthenticatedPage title={state.title}>
      <Content dangerouslySetInnerHTML={{ __html: state.post }}></Content>
    </AuthenticatedPage>
  )
}
