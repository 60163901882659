const PRODUCTION = 'production'

export const Env = {
  isProduction: () => process.env.REACT_APP_ENV === PRODUCTION,

  AUTH_URI: 'AUTH_URI',
  KRAKEN_URI: 'KRAKEN_URI',
  KRAKEN_KEY: 'KRAKEN_KEY',

  getEnv: (service) => {
    const envs = {
      production: {
        AUTH_URI: 'https://api.cerbero.somar.io',
        KRAKEN_URI: 'https://kraken.somar.io',
        KRAKEN_KEY: process.env.REACT_APP_KRAKEN_API_KEY
      },
      development: {
        AUTH_URI: 'https://dev.api.cerbero.somar.io',
        KRAKEN_URI: 'https://kraken.somar.io',
        KRAKEN_KEY: process.env.REACT_APP_KRAKEN_API_KEY
      }
    }

    return envs[process.env.REACT_APP_ENV ?? PRODUCTION][service]
  }
}
